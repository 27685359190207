.container-editable {
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 0;
    padding: 5px;
}

.container-editable p {
    max-height: 100%;
    max-width: 75%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-editable button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    max-height: 100%;
    max-width: 20%;
    border-radius: 8px;
    height: 30px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-editable input {
    max-height: 100%;
    height: 25px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 0;
    margin: 0;
    width: 75%;
}

.container-editable svg {
    width: 18px;
    height: 18px;
}