#delete-model-popup {
    width: 260px;
    height: auto;
    display: flex;
    flex-direction: column;
}

#delete-model-popup #delete-title {
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    position: absolute;
    left: 20px;
    top: 20px;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 20px;
}

#delete-model-popup #description {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    margin-top: 40px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

#delete-model-popup #buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
