.container-startpage {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('../../../images/home.svg');
    background-position: center center;
    background-size: cover;
    flex-direction: row;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #FDFDFD;
    padding: 0px 232px;
    background-color: black;
    overflow-y: initial;
    height: 100vh;
}

/* ::-webkit-scrollbar {
    display: none;
} */

.container-startpage .container-logo {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.container-startpage .container-logo article {
    margin-top: 60px;
    font-size: 64px;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.container-startpage .container-logo article p {
    margin: 0;
    padding: 0;
}

.container-startpage .container-login {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDFDFD;
    width: 346px;
    height: 420px;
    border-radius: 10px;
    color: #000;
    overflow: hidden auto;
    font-weight: 600;
    font-size: 14px;
}

.container-startpage .auth-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container-startpage .auth-card #beta-warning {
    display: flex;
    justify-content: center;
    width: 80%;
    padding: 6px;
    gap: 10px;
    background-color: #EB5757;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    max-width: 258px;
    max-height: 25px;
    margin-top: 18px;
    margin-left: 44px;
    border-radius: 6px;
}

.container-startpage .auth-card .tabs {
    display: flex;
    flex-direction: row;
    height: 42px;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border: none;
    padding: 0;
    background-color: #FDFDFD;
}

.container-startpage .auth-card .tabs .login-tab {
    width: 50%;
    display: flex;
    height: 42px;
    background-color: #E5E5E5;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px 0px 10px 10px;
    border: none;
}

.container-startpage .auth-card .tabs .signup-tab {
    width: 50%;
    display: flex;
    height: 42px;
    background-color: #E5E5E5;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 0px 10px 10px 10px;
    border: none
}

.container-startpage .auth-card .tabs .login-tab:hover {
    cursor: pointer;
}

.container-startpage .auth-card .tabs .signup-tab:hover {
    cursor: pointer;
}

.container-startpage .auth-card .tabs .tab--selected {
    width: 50%;
    display: flex;
    height: 42px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 0px 10px;
    background-color: transparent;
    border: none;
}

.container-startpage .auth-card .tabs .tab--selected:hover {
    cursor: default;
}

.container-startpage .auth-card .auth-login {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.container-startpage .auth-card .auth-signup {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    overflow-y: auto;
    overflow-x: hidden;
}

.container-startpage .auth-card .auth-login .input-title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-left: 40px;
    margin-bottom: 5px;
}

.container-startpage .auth-card .auth-login .text-input input[type="text"] {
    background-color: transparent;
    color: black;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.container-startpage .auth-card .auth-login .password-input input[type="password"] {
    background-color: transparent;
    color: black;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    letter-spacing: 0.2px;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.container-startpage .auth-card .auth-signup .password-input input[type="password"] {
    background-color: transparent;
    color: black;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    letter-spacing: 0.2px;
    text-align: left;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-left: 40px;
    margin-right: 40px;
}

.container-startpage .auth-card .auth-signup .list-input {
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    position: relative;
}

.container-startpage .auth-card .auth-login .login-buttons {
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.container-startpage .auth-card .auth-login .login-buttons #password-recover {
    margin-right: 34.4px;
}

.container-startpage .auth-card .auth-login .login-buttons #password-recover #recover-text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.2px;
}

.container-startpage .auth-card .auth-signup .input-title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
    margin-bottom: 5px;
    padding-left: 40px;
    padding-right: 40px;
    overflow-wrap: break-word;
    text-align: start;
}

.container-startpage .auth-card .auth-signup .text-input input[type="text"] {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}

.container-startpage .auth-card .auth-signup .date-input input[type="date"] {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    text-align: left;
    margin-left: 40px;
    margin-right: 40px;
}

.container-startpage .auth-card .auth-signup .login-buttons {
    margin-top: 20px;
    margin-left: 44.7px;
    display: block;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
}

@media (max-width: 1400px) {

    .container-startpage {
        width: 100%;
        padding: 0px 180px;
    }

}

@media (max-width: 1200px) {

    .container-startpage {
        width: 100%;
        padding: 0px 120px;
    }

    .container-startpage .container-logo article {
        margin-top: 40px;
    }

    .container-startpage .container-logo article p {
        font-size: 40px;
    }

}

@media (max-width: 960px) {

    .container-startpage {
        padding: 0px 80px;
    }

    .container-startpage .container-logo article {
        margin-top: 35px;
    }

    .container-startpage .container-logo article p {
        font-size: 35px;
    }

}

@media (max-width: 760px) {

    .container-startpage {
        padding: 0 60px;
    }

    .container-startpage .container-logo img {
        width: 80%;
    }

    .container-startpage .container-logo article {
        margin-top: 20px;
    }

    .container-startpage .container-logo article p {
        font-size: 30px;
    }

    .container-startpage .container-login {
        width: 280px;
        height: 350px;
    }
}

.dropdown-signup {
    border-radius: 10px !important;
    font-size: 12px !important;
    letter-spacing: 0.2px !important;
    line-height: 1em !important;
    border: 1px solid #BDBDBD !important;
}

@media (max-width: 600px) {
    .container-startpage {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .container-startpage .container-logo {
        position: absolute;
        top: 0px;
        left: 0px;
        flex-direction: row;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-align: center;
    }

    .container-startpage .container-logo img {
        width: 25%;
        height: 80%;
    }

    .container-startpage .container-logo article {
        padding: 0;
        margin: 0;
        flex-direction: row;
        height: 80%;
        width: 60%;
        gap: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-startpage .container-logo article p {
        font-size: 15px;
    }

    .container-startpage .container-login {
        margin-top: 20px;
        height: 70%;
        width: 80%;
    }
}