.main {
    display: flex;
    width: 100%;
    gap: 5em;
}

.rocCurve {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerOptions {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 25%;

}

.categories {
    display: flex;
    align-items: center;
    gap: 0.6em;
}

.categories select {
    width: auto;
    border: 1px solid #ccc;
    padding: 0.75em 1.5em 0.75em 1.5em;
    border-radius: 0.7em;
    background-color: #f9f9f9;
}

.models {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
}

.models header {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    border-radius: 10px 10px 0 0;
    border: 1px solid #E0E0E0;
    background-color: #F4F4F4;
    padding: 12px 24px;
}

.models ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.models ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid #E0E0E0;
}

.models ul li input[type="checkbox"] {
    transform: scale(1.5);
}

@media(max-width:768px) {
    .main {
        flex-direction: column;
        gap: 2em;
    }

    .rocCurve {
        width: 100%;
    }


    .containerOptions {
        width: 15em;
    }

}