.containerMyDatasets {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    letter-spacing: 0.2px;
    background: url("../../../images/backgroubd_myprojects_1376.svg");
    background-position: center center;
    background-size: cover;
}

.containerMyDatasets .headerMyDatasets #video {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 10px;
    position: relative;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    overflow: hidden;
    width: auto;
    max-height: 736px;
    min-height: 430px;
    min-width: 900px;
    max-width: 1190px;
    height: 736px;
}

.video {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

.containerMyDatasets .contentMyDatasets #image {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    overflow: hidden;
    width: auto;
    max-height: 736px;
    min-height: 430px;
    min-width: 900px;
    max-width: 1190px;
    height: 736px;
}

.containerMyDatasets .contentMyDatasets #image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.containerMyDatasets .title {
    padding-left: 20px;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
}

.containerMyDatasets .title p {
    height: 100%;
    width: 60%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.containerMyDatasets .headerMyDatasets {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.containerMyDatasets .headerMyDatasets button {
    background: #22577A;
    color: #ececec;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-style: normal;
    height: 40px;
    cursor: pointer;
    padding: 0 8px;
}

.containerMyDatasets .headerMyDatasets p {
    font-size: 24px;
    font-weight: 300;
    height: 20%;
    padding: 0px;
    margin: 0px;
}

.containerMyDatasets .headerMyDatasets .headerButtonInput {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    gap: 25%;
    justify-content: center;
}

.containerMyDatasets .container-checkbox {
    margin: 0;
    margin-right: 2rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.containerMyDatasets .checkbox-labeled {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-left: 20px;
    align-items: center;
    justify-content: end;
    font-size: 14px;
    font-weight: 500;
    gap: 20px;
}

.containerMyDatasets .checkbox-labeled #new-dataset {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    outline: none;
    background-color: transparent;
    height: 100%;
    border: none;
    cursor: pointer;
}

.containerMyDatasets .checkbox-labeled .trash-icon {
    cursor: pointer;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    outline: none;
}

.containerMyDatasets .checkbox-labeled .select-all {
    display: flex;
    justify-content: flex-end;
    margin-right: 5rem;
    margin-top: 1rem;
}

.containerMyDatasets .contentMyDatasets {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    flex-wrap: wrap;
}


.containerMyDatasets .contentMyDatasets a {
    height: 10rem;
}


.containerMyDatasets .contentMyDatasets .noDatasets {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    color: lightgrey;
    padding-left: 0.6rem;
    margin-top: 2rem;
}

@media (max-width: 425px) {
    .containerMyDatasets {
        padding: 10px 0px;
    }

    .containerMyDatasets .headerMyDatasets,
    .containerMyDatasets .title,
    .containerMyDatasets .checkbox-labeled {
        padding-left: 10px;
        flex-wrap: wrap;
    }
}

@media (max-width: 550px) {

    .containerMyDatasets .checkbox-labeled {
        padding-right: 2rem;
    }

    .containerMyDatasets .checkbox-labeled svg {
        width: 22px;
        height: 22px;
    }

    .containerMyDatasets .checkbox-labeled #new-dataset {
        display: flex;
    }
}