/* alterações só quando dar F5 */
.randomforestd3-result {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.randomforestd3-result .select-target {
    height: 27px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
}
  