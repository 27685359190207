.filtro-colaborativo {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    height: 87%;
    width: auto;
    flex-direction: column;
}

.filtro-colaborativo #title {
    margin-left: 20px;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
}

.filtro-colaborativo .content {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 400;
    width: auto;
    height: 89.82%;
}

.filtro-colaborativo .content .left {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 19.69%;
    height: 100%;
    text-align: left;
    align-items: flex-start;  
}

.filtro-colaborativo .content .left .buttonExecut {
    width: 90%;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    background: #22577A;
    border-radius: 8px;
    margin: 0px auto 0px auto;
    color: #FDFDFD;
    gap: 10px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.filtro-colaborativo.content .left .buttonExecut:hover {
    background-color: #2f729e;
    color: #ececec;
}

.filtro-colaborativo .content .left .model-type {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
}

.filtro-colaborativo .content .left .model-type .ic {
    margin-right: 10px;
}

.filtro-colaborativo .content .left .dataset-name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.filtro-colaborativo .content .left .dataset-name .ic {
    margin-right: 10px;
}

.filtro-colaborativo .content .left .parameter-desc {
    font-weight: 500;
    color: rgb(138, 138, 138);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: justify;
    display: block;
    line-height: 13px;
}

.filtro-colaborativo .content .left .exec-btn {
    margin-top: 15px;
}

.filtro-colaborativo .content .filtrocolaborativo-result {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    height: 100%;
}

.filtro-colaborativo .containerBottom {
    position: relative;
    width: 100%;
    height: 11%;
    display: flex;
    align-items: center;
}

.filtro-colaborativo .containerBottom .popup-bottom {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    position: absolute;
    right: 0px;
    gap: 30px;
}

.filtro-colaborativo .containerBottom .popup-bottom  div {
    display: flex;
    align-items: center;
    color: #22577A;
    cursor: pointer;
}

.filtro-colaborativo .content #graphical-data #save-status {
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    size: 12px;
    line-height: 15px;
    color: red;
}

.filtro-colaborativo .content #graphical-data #save-status #save-status-icon {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    padding-left: 2px;
    padding-right: 8px;
    border-radius: 5px;
    margin-top: -5px;
}