.user-card {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    padding: 8px;
    width: 200px;
    cursor: pointer;
    justify-content: space-between;
    border: none;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.user-card:hover {
    background-color: #F2F2F2
}

.user-card .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    max-width: 120px;
}

.user-card .user-info .user-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.user-card .user-info .user-email {
    font-size: 11px;
    color: #00000080;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.user-card #user-profile-picture {
    height: 32px;
    width: 32px;
    overflow: hidden;
    object-fit: scale-down;
    border-radius: 50%;
}