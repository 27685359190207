.containerAvailableWiseml {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    gap: 10px;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-bottom: auto;
}

.containerAvailableWiseml a {
    padding: 0px;
    margin: 0;
}
