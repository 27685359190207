.containerEditApiKeyPopup {
    width: 500px;
    height: 380px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.2px;
    line-height: 16px;
}

.containerEditApiKeyPopup p,
.containerEditApiKeyPopup span {
    margin: 0px;
    padding: 0px;
    text-align: left;
}

.containerEditApiKeyPopup .containerTitle {
    width: inherit;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 300;
}

.containerEditApiKeyPopup .containerInfo {
    width: inherit;
    height: 320px;
    display: flex;
    flex-direction: row;
}

.containerEditApiKeyPopup .containerInfo #title {
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    width: 90%;
}

.containerEditApiKeyPopup .containerInfo .noActive {
    color: #BDBDBD;
}
.containerEditApiKeyPopup .containerInfo .containerEdit {
    width: 250px;
    height: inherit;
    display: flex;
    flex-direction: column;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .containerName,
.containerEditApiKeyPopup .containerInfo .containerEdit .containerValidity {
    width: inherit;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.containerEditApiKeyPopup .containerInfo .containerEdit header {
    width: inherit;
    height: 25px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity {
    width: inherit;
    height: 145px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    padding-top: 10px;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity p,
.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress p {
    width: 90%;
    height: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity input,
.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity select,
.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress select,
.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress input {
    width: 90%;
    height: 30px;
    padding-left: 12px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    outline: none;
    background-color: transparent;
}


.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity select,
.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress select {
    cursor: pointer;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress {
    width: inherit;
    height: 275px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    padding-top: 10px;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress button,
.containerEditApiKeyPopup .containerButton button {
    height: 30px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress button {
    width: 60%;
    color: #F2F2F2;
}

.containerEditApiKeyPopup .containerInfo .containerEdit .switchAddress .buttonActive, 
.containerEditApiKeyPopup .containerInfo .containerEdit .switchNameValidity .buttonActive {
    background-color: #22577A;
}

.containerEditApiKeyPopup .containerInfo .buttonInactive {
    background: rgba(28, 27, 31, 0.12);
}

.containerEditApiKeyPopup .containerInfo .containerEdit #errorMessage {
    color: red;
}

.containerEditApiKeyPopup .containerInfo .containerEdit #successMessage {
    color: green;
    font-size: 11px;
}

.containerEditApiKeyPopup .containerButton {
    width: inherit;
    height: 30px;
    margin: 0;
    padding: 0px 0px 0px calc(100% - 270px);
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.containerEditApiKeyPopup .containerButton button {
    width: 130px;
}

.containerEditApiKeyPopup .containerButton #editButton{
    color: #F2F2F2;
    background-color: #22577A;
}

.containerEditApiKeyPopup .containerButton #closedButton {
    background-color: transparent;
    color: #22577A;
}
