.anomalies {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
}

.anomalies .anomalies-head {
    width: 100%;
    border-radius: 4px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 6px; 
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
}

.anomalies .anomalies-head:hover {
    background-color: #E8EAED;
    border-radius: 5px;
}

.anomalies .anomalies-head p {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.anomalies .anomalies-head .anomalies-score {
    width: 57.15%;
    height: 50%;
    border-radius: 50px;
    background: #E8EAED;
}

.anomalies .anomalies-head .anomalies-score div {
    height: 100%;
    background-color: #38A3A5;
    border-radius: 50px;
}

.anomalies .anomalies-head .anomalies-arrow {
    width: 8%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.anomalies .anomalies-histogram {
    border-top: 1px solid #E8EAED; 
    width: 100%;
    height: calc(100% - 31.62%);
    display: flex;
}

@media (max-width: 600px) {

    .anomalies .anomalies-head {
        padding: 1px 3px; 
        gap: 0px;
    }

    .anomalies .anomalies-head p {
        font-size: 10px;
    }

    .anomalies .anomalies-head .anomalies-score {
        width: 50%;
        height: 40%;
        border-radius: 50px;
        background: #E8EAED;
    }
    
}

@media (max-width: 500px) {

    .anomalies .anomalies-head {
        padding: 6px; 
        gap: 10px;
    }

    .anomalies .anomalies-head p {
        font-size: 15px;
    }

}