.prediction-popup p {
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #282828;
}


.prediction-popup {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 400px;
    height: 541px;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
}

.prediction-popup header {
    width: 100%;
    /* height: 82px; */
    margin: 0;
    padding: 0;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #E5E5E5
}

.prediction-popup header .title {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.prediction-popup header .title p {
    height: auto;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
}

.prediction-popup header ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    gap: 20px;
    justify-content: center;
    align-items: flex-end;
}

.prediction-popup header ul li {
    width: auto;
    height: auto;
    list-style-type: none;
}

.prediction-popup header ul li button {
    padding: 8px 5px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #38A3A5;
    transition: 0.5s;
    cursor: pointer;
}

.prediction-popup header ul li button:hover {
    padding: 15px 5px;
}

.prediction-popup header ul li button p {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
}

.prediction-popup main {
    width: 100%;
    height: calc(541px - 82px);
    padding: 10px 8px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    overflow: hidden auto;
}

.prediction-popup main article {
    width: 100%;
    height: auto;
    text-align: start;
}

.prediction-popup main article p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.single-prediction {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.single-prediction .target {
    width: 212px;
    height: 30px;
    border: 1px solid #808080;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 8px;
}

.single-prediction .target p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #808080;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;
}
.single-prediction .fields-list {
    width: 100%;
    height: 62%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}

.single-prediction .fields-list .field {
    width: 95%;
    height: 30px;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    align-items: center;
}

.single-prediction .field p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70%;
}

.single-prediction .field input,
.single-prediction .field select,
.single-prediction .answer .field {
    margin: 0;
    width: 25%;
    height: 100%;
    border: 1px solid #BDBDBD;
    outline: none;
    border-radius: 10px;
    background-color: transparent;
    padding: 5px;
}

/* Chrome e outros */
.single-prediction .field input::-webkit-outer-spin-button,
.single-prediction .field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.single-prediction .field input[type=number] {
    -moz-appearance: textfield;
}

.single-prediction .field select {
    cursor: pointer;
}

.button-prediction {
    width: 100%;
    height: auto;
    cursor: pointer;
    padding: 8px 0px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #22577A;
    border: none;
    border-radius: 8px;
}

.disabled {
    background-color: #808080;
    cursor: not-allowed;
}

.single-prediction .answer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.single-prediction .answer #attribute {
    padding: 0;
    margin: 0;
    font-weight: 700;
    color: #808080;
    font-size: 12px;
    line-height: 15px;
    max-width: 70%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.single-prediction .answer .field p {
    padding: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    color: #808080;
    font-size: 12px;
    line-height: 15px;
}

.batch-prediction {
    margin: auto;
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    overflow-y: auto;
}

/* .batch-prediction .list-cards {
    display: flex;
    flex-direction: column;
    height: 75%;
} */

.batch-prediction label {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    cursor: pointer;
}

.batch-prediction label p {
    color: #FFFFFF;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
}

.prediction-popup main .file {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prediction-popup main .file p {
    color: #282828;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    text-transform: none;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
}

.prediction-popup main .file button {
    width: auto;
    padding: 8px 20px;
}

.prediction-popup .table {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.prediction-popup .table button {
    background-color: transparent;
}

.prediction-popup .table #download {
    background-color: transparent;
    border: 1px solid #22577A;
}

.prediction-popup .table #download p {
    color: #22577A;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
}

/* TABS */
.tabs {
    display: flex;
    justify-content: space-around;
}

.tab {
    padding: 7px 12px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 14px;
    border-radius: 5px;
}

.tab:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}

.tab.active {
    background-color: #22577A;
    color: white;
    font-weight: bold;
}