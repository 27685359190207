.email-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    gap: 1em;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    margin: 1rem auto;
}

.response {
    display: flex;
    align-items: center;
    gap: 0.3em;
    color: black;
}

.buttons-pin {
    display: flex;
    gap: 1em;
}

.error {
    color: red;
}