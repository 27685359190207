.isloading {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.containermongodb p,
.containermongodb h2 {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
}

.containermongodb {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.containermongodb .containergraphic .graphic,
.containermongodb .containergraphic .logo,
.containermongodb .containerlogs .tablelogs {
    border-radius: 15px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.containermongodb .containergraphic,
.containermongodb .containerlogs {
    width: 80%;
}


.containermongodb .containergraphic {
    height: 30%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.containermongodb .containergraphic .graphic {
    width: calc(100% - 320px);
    height: 100%;
}

.containermongodb .containergraphic .logo {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containermongodb .containergraphic .logo img {
    max-width: 90%;
    width: auto;
    max-height: 90%;
    height: auto;
}

.containermongodb .containerlogs {
    height: 65%;
    display: flex;
    flex-direction: column;
}

.containermongodb .containerlogs h2 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
}

.containermongodb .containerlogs .tablelogs h2 {
    font-size: 15px;
    font-weight: 500;
}

.containermongodb .containerlogs .tablelogs {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}

.containermongodb .containerlogs .tablelogs header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: space-between;
}

.containermongodb .containerlogs .tablelogs header span {
    border-right: 1px solid #CECECE;
    height: 100%;
    width: auto;
}

.containermongodb .containerlogs .tablelogs .logs {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
}

.containermongodb .containerlogs .tablelogs .logs button {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    border: none;
    transition: 0.5s;
    background-color: transparent;
    outline: none;
}

.containermongodb .containerlogs .tablelogs .logs button:hover {
    background-color: #CECECE;
} 

.containermongodb .containerlogs .tablelogs .date {
    width: 20%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containermongodb .containerlogs .tablelogs .log {
    width: 80%;
    height: 50px;
    display: flex;
    align-items: center;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containermongodb .containerlogs .tablelogs .logs button p {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    padding: 1px;
}

.containermongodb .containerjson {
    font-size: 14px;
    color: #333;
    background-color: #f8f8f8;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 100%;
    overflow: auto;
    display: flex;
    text-align: left;
    width: auto;
    height: auto;
    max-width: 700px;
    max-height: 500px;
}

@media (max-width: 1200px) {

    .containermongodb .containergraphic .graphic {
        width: calc(100% - 250px);
    }
    
    .containermongodb .containergraphic .logo {
        width: 250px;
    }
}

@media (max-width: 1000px) {

    .containermongodb {
        gap: 15px;
    }

    .containermongodb .containergraphic,
    .containermongodb .containerlogs {
        width: 96%;
    }

    .containermongodb .containergraphic .graphic {
        width: calc(100% - 200px);
    }
    
    .containermongodb .containergraphic .logo {
        width: 200px;
    }
}

@media (max-width: 800px) {

    .containermongodb .containergraphic,
    .containermongodb .containerlogs {
        width: 100%;
    }

    .containermongodb .containergraphic .graphic {
        width: calc(100% - 150px);
    }
    
    .containermongodb .containergraphic .logo {
        width: 150px;
    }
}

@media (max-width: 750px) {
    .containermongodb .containerjson {
        max-width: 600px;
        max-height: 550px;
    }
}

@media (max-width: 650px) {
    .containermongodb .containerjson {
        max-width: 450px;
    }
}

@media (max-width: 500px) {

    .containermongodb {
        z-index: 0;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden auto;
        margin-top: 3.5px;
    }

    .containermongodb .containergraphic {
        width: 100%;
        height: auto;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .containermongodb .containergraphic .graphic {
        width: 100%;
        height: 300px;
    }

    .containermongodb .containergraphic .logo {
        width: 100%;
        height: 200px;
        max-width: 100%;
    }

    .containermongodb .containerlogs {
        width: 100%;
        height: auto;
        max-height: 450px;
    }

    .containermongodb .containerjson {
        max-width: 400px;
        height: 500px;
    }

    .containermongodb .containerlogs .tablelogs .date,
    .containermongodb .containerlogs .tablelogs .log  {
        font-size: 11px;
    }

    .containermongodb .containerlogs .tablelogs .logs button {
        height: 40px;
    }

    .containermongodb .containerlogs .tablelogs .logs button p {
        font-size: 10px;
    }
}

@media (max-width: 430px) {
    .containermongodb .containerjson {
        max-width: 350px;
    }
}

@media (max-width: 400px) {
    .containermongodb .containerjson {
        max-width: 300px;
    }
}