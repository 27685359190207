.containerNewProjectPopup {
    width: 210px;
    height: 338px;
    display: flex;
    flex-direction: column;
    font-family: Montserrat; 
    font-style: normal;
    letter-spacing: 0.2px; 
    text-align: left;
    justify-content: space-between;
}

.containerNewProjectPopup .containerTitle {
    width: 100%;
    height: 9.26%;
    display: flex;
    font-size: 24px; 
    line-height: 34px; /* 141.667% */ 
    font-weight: 300; 
}

.containerNewProjectPopup .containerInput {
    width: 100%;
    height: 52.38%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px; /* 125% */
}

.containerNewProjectPopup .containerInput input,
.containerNewProjectPopup .containerInput textarea {
    border-radius: 10px;
    border: 1px solid var(--Gray-4, #BDBDBD);
    outline: none;
    resize: none;
    padding-left: 10px;
}

.containerNewProjectPopup .containerInput textarea {
    padding-top: 10px;
}

.containerNewProjectPopup .containerInput .containerName {
    width: 100%;
    height: 27.78%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerNewProjectPopup .containerInput .containerName input {
    width: 100%;
    height: 54.54%;
}

.containerNewProjectPopup .containerInput .containerDescription {
    width: 100%;
    height: 62.12%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerNewProjectPopup .containerInput .containerDescription textarea {
    width: 100%;
    height: 65.04%;
}

.containerNewProjectPopup button {
    width: 100%;
    height: 8.47%;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    text-transform: uppercase; 
}

.containerNewProjectPopup .containerInput .containerDescription span {
    width: 100%;
    display: flex;
    justify-content: end;
}

.containerNewProjectPopup .create {
    background: var(--blue-sapphire, #22577A); 
    color: #FDFDFD; 
    cursor: pointer;
}

.containerNewProjectPopup .creating {
    background-color: #e0e0e0;
    color: #f2f2f2;
}