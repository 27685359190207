.containerUploadDataset{
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    letter-spacing: 0.2px;
    flex-direction: column;
    gap: 15px;
}

.containerUploadDataset ul {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 50px;
}

.containerUploadDataset ul li {
    list-style-type: disc;
}

.containerUploadDataset label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #22577A;
    padding: 8px 20px;
    text-transform: uppercase;
    border-radius: 8px;
    border: none;
    color: #FDFDFD;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
}

.containerUploadDataset .containerFile {
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    margin: auto auto;
}

.containerUploadDataset .containerFile p {
    position: absolute;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.containerUploadDataset .containerFile span {
    font-family: 15px;
}

@media (max-width: 940px) {

    .containerUploadDataset ul {
        gap: 10px;
    }

    .containerUploadDataset ul li {
        font-size: 1.2em;
    }
}

@media (max-width: 500px) {

    .containerUploadDataset label {
        font-size: 11px;
    }
}

@media (max-width: 400px) {

    .containerUploadDataset label {
        font-size: 9px;
    }
}