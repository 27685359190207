.text-input-component {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 363px;
    height: 32px;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    /* padding: 8px 10px 8px 10px; */
}

.text-input-component input.text-input {
    padding: 8px 10px 8px 10px;
    background-color: transparent;
    font-size: 12px;
    font-family: 'Montserrat';
    border: 0;
    width: 100%;
}

.text-input-component input:focus, textarea:focus {
    outline: cadetblue;
}
