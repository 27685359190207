.containerWebAdressDataset {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.2px; 
}

.containerWebAdressDataset .containerInput {
    display: flex;
    flex-direction: column;
    height: 16.61%;
    width: 34.21%;
}

.containerWebAdressDataset .containerInput label {
    width: 100%;
    height: 45.45%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.containerWebAdressDataset .containerInput input {
    width: 100%;
    height: 54.55%;
    border-radius: 10px;
    padding-left: 10px;
    border: 1px solid #282828;
}