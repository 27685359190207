.target-model {
    display: flex;
    border-radius: 0.8rem;
    border-color: #57CC99;
    align-items: center;
    padding: 0 0.5rem;
    width: 100%;
    border: 1px solid var(--medium-aquamarine, #57CC99);
    height: 35px;
    position: relative;
}

.target-model .target-model-svg {
    max-width: 10%;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 95%;
    position: absolute;
}

.target-model .target-model-svg svg {
    width: 20px;
    height: 20px;
}

.target-model #mainAttribute {
    border: none;
    width: 100%;
    background-color: transparent;
    outline: none;
    height: 95%;
    cursor: pointer;
    padding-left: 15%;
}

@media (max-width: 1000px) {
    
    .target-model {
        height: 25px;
    }
    
    .target-model #mainAttribute {
        font-size: 11px;
    }
}

@media (max-width: 1000px) {
    
    .target-model {
        height: 35px;
    }
    
    .target-model #mainAttribute {
        font-size: 13.5px;
    }
}