.share-model-popup {
    display: flex;
    flex-direction: column;
    width: 490px;
    height: auto;
    align-items: flex-start;
    font-family: "Montserrat";
    box-sizing: border-box;
    letter-spacing: 0.2px;
}

.share-model-popup .title {
    margin-bottom: 24px;
}

.share-model-popup .title #title-text {
    font-size: 24px;
    line-height: 34px;
    font-weight: 300;
}

.share-model-popup .popup-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}



.share-model-popup .popup-content .user-cards .users-list {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.share-model-popup .popup-content .user-cards .users-list .list-heading {
    font-size: 14px;
    margin-bottom: 10px;
}

.share-model-popup .popup-content .user-cards .users-list .list-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    max-height: 120px;
    overflow: auto;
}

div#shared-list.users-list {
    margin-bottom: 24px;
}

.shared-success {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #57cc9946;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.shared-error {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #d9544fcb;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}