table {
    font-family: Montserrat;
    margin-right: 20px;
}

tr {
    display: table-row;
    height: 43px;
    padding-left: 50px;
    margin-right: 5px;
}

td {
    height: auto;
}

body {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #373F41;
    background-color: #fff;
    text-align: center;
}