.container-project {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    letter-spacing: 0.2px;
    background: url("../../../images/backgroubd_myprojects_1376.svg");
    background-position: center center;
    background-size: cover;
}

.container-project .header {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-family: Montserrat, sans-serif;
}

.container-project .header .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
    margin: 0;
}

.container-project .header .title p {
    height: 100%;
    width: 60%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.container-project .container-cards {
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    padding: 0px;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.container-project .container-cards .container-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0px;
}


.container-project .notification-icon {
    margin-right: 120px;
    width: 22px;
    height: 24px;
}

.container-project .notification-icon:hover {
    cursor: pointer;
}

.container-project .dataSetsContent {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding-left: 5px;
}

.container-project .dataSetsContent a {
    cursor: pointer;
}

.container-project .dataSetsContent .containerCard {
    position: relative;
    padding: 1px;
    border-radius: 8px;
}

.container-project .dataSetsContent .containerCard .containerBox {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 20px;
    height: 20px;
    border: 2.5px solid #c9c7c7;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-project .dataSetsContent .selected {
    background: #dfdddd;
}

.container-project .modelsContent {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    padding-left: 5px;
}

#no-data {
    margin-top: 15px;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    letter-spacing: 0.2;
    color: lightgrey;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 10px;
    align-items: flex-start;
}

.container-project .section-title {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;
    padding-left: 20px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
}

.container-project .section-title .checkbox-labeled {
    margin-right: 5rem;
    gap: 1.4rem;
    width: 40px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-project .section-title .checkbox-labeled .trash-icon {
    cursor: pointer;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    outline: none;
}

.container-project .section-title .new-dataset,
.models-section {
    display: flex;
    align-items: center;
}

.container-project .section-title .select-all {
    margin-right: 2rem;
}


.container-project .section-title #icon {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
}

.container-project .section-title #icon:hover {
    cursor: pointer;
    background: #E5E5E5;
}

.container-project .drag-import {
    font-family: Montserrat, sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    background-color: #FDFDFD;
    margin: 20px;
}

.upload-success {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #57cc9946;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

@media (max-width: 450px) {

    .container-project {
        padding: 10px 0px;
    }

    .container-project .header,
    .container-project .section-title  {
        padding-left: 10px;
    }

    .container-project .section-title .checkbox-labeled {
        margin-right: 2rem;
    }

}
