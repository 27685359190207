#save-status {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: red;
    background-color: transparent;
    width: fit-content;
    z-index: 1;
    align-items: center;
    gap: 0.2rem;
    margin-top: 0.4rem;
}

#save-status #save-status-icon {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    padding-left: 2px;
    padding-right: 8px;
    border-radius: 5px;
}