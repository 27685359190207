.dashboard p,
.dashboard h2 {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    padding: 0;
    margin: 0;
}

.isloading {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.dashboard {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
}

.dashboard .connectedusers {
    width: 500px;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.dashboard .connectedusers header {
    width: 100%;
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px solid #c0bfbf;
    box-shadow: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 20px;
}

.dashboard .connectedusers header p {
    font-size: 15px;
    font-weight: 500;
    max-width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dashboard .connectedusers .users {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 100%;
    height: auto;
    overflow-y: auto;
    flex-wrap: wrap;
}

.dashboard .connectedusers .users .user {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.dashboard .connectedusers .users .user p {
    font-size: 15px;
    letter-spacing: 0.4px;
    max-width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dashboard .connectedusers .users .user div {
    width: 20px;
    height: 20px;
    background-color: #57CC99;
    border-radius: 100%;
}

.dashboard .containerServices {
    width: calc(100% - 500px);
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard .containerServices .headertitle {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
}

.dashboard .containerServices .headertitle p {
    font-size: 15px;
    font-weight: 500;
}

.dashboard .containerServices .services {
    gap: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.dashboard .containerServices .services .service {
    width: 400px;
    height: 240px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.dashboard .containerServices .services .service header{
    box-shadow: none;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dashboard .containerServices .services .service header p {
    font-size: 30px;
    font-weight: 400;
    color: #22577a;
}

.dashboard .containerServices .services .service .serviceinfo {
    width: 100%;
    height: calc(100% - 50px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
}

.dashboard .containerServices .services .service .serviceinfo .info {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.dashboard .containerServices .services .service .serviceinfo .info div {
    width: auto;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
}

.dashboard .containerServices .services .service .serviceinfo .info div h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dashboard .containerServices .services .service .serviceinfo .info div p {
    font-size: 12.5px;
    font-weight: 400;
    line-height: 20px;
    color: #22577a;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dashboard .containerServices .services .service .buttons {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    overflow: hidden;
    gap: 15px;
}

.dashboard .containerServices .services .service .buttons img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 70%;
}

.dashboard .containerServices .services .service .buttons button {
    margin-left: auto;
    cursor: pointer;
    background-color: #22577a;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 15px;
    max-width: 100%;
    width: auto;
    height: auto;
}

.dashboard .containerServices .services .service .buttons button:hover {
    background-color: #2f729e;
}

.dashboard .containerServices .services .service .buttons button p {
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 1450px) {

    .dashboard .containerServices {
        width: calc(100% - 350px);
    }

    .dashboard .connectedusers {
        width: 350px;
    }

}

@media (max-width: 1300px) {

    .dashboard .containerServices .services .service {
        width: 300px;
        height: 230px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

}

@media (max-width: 1100px) {

    .dashboard .containerServices .services .service {
        width: 100%;
        height: 200px;
    }

}

@media (max-height: 870px) {

    .dashboard .containerServices {
        overflow-y: auto;
    }

}

@media (max-width: 750px) {

    .dashboard {
        padding: 10px;
        gap: 0px;
    }

    .dashboard .containerServices {
        width: calc(100% - 220px);
    }

    .dashboard .connectedusers {
        width: 220px;
    }
}

@media (max-width: 600px) {

    .dashboard .containerServices .services .service .serviceinfo .info div h2 {
        font-size: 12px;
    }
    
    .dashboard .containerServices .services .service .serviceinfo .info div p {
        font-size: 11px;
    }

    .dashboard .containerServices .services .service .buttons button p {
        font-size: 12px;
    }

}


@media (max-width: 500px) {

    .dashboard{
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        gap: 20px;
        padding: 20px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .dashboard .connectedusers {
        width: 100%;
        max-height: 300px;
        min-height: 180px;
        height: auto;
    }

    .dashboard .containerServices {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 0;
        max-height: max-content;
    }

    .dashboard .containerServices .services {
        gap: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: inherit;
        padding: 0px 0px 15px 0px;
    }

    .dashboard .containerServices .services .service .serviceinfo .info div h2 {
        font-size: 14px;
    }
    
    .dashboard .containerServices .services .service .serviceinfo .info div p {
        font-size: 12.5px;
    }

    .dashboard .containerServices .services .service .buttons button p {
        font-size: 14px;
    }
}