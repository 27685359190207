.containerImplementCode {
    width: 700px;
    height: 528px;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.2px;
    color: #282828;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    text-align: left;
    gap: 24px;
}

.containerTitlePopup {
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    width: 672px;
    height: 34px;
    display: flex;
    align-items: center;
}

.containerInputs {
    margin-top: 24px;
    width: 364px;
    height: 55px;
    display: flex;
    flex-direction: row;
    margin: 0px;
    gap: 24px;
}

.containerInputs div {
    width: 170px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerInputs div select {
    cursor: pointer;
    width: 100%;
    height: 30px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    background-color: transparent;
    padding-left: 12px;
    color: #282828;
}

.containerCode {
    width: inherit;
    height: 300px;
    background: #595959;
    border-radius: 6px;
    overflow: scroll;
    padding: 10px;
    color: #F2F2F2;
}

.containerCode pre {
    margin: 0;
    padding: 0;
}

.containerImplementCode #buttonGeneratedCode {
    position: absolute;
    top: 511px;
    right: 20px;
    width: 150px;
    height: 32px;
    margin-top: 10px;
    background-color: #22577A;
    color: #F2F2F2;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;

}