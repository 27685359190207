.containerinfouserpopup p,
.containerinfouserpopup h2,
.containerinfouserpopup input,
.containerinfouserpopup label {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    padding: 0;
    margin: 0;
}

.containerinfouserpopup li {
    list-style-type: none;
}

.isloading {
    height: 80vh;
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.containerinfouserpopup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    width: 80vw;
    gap: 20px;
    overflow: hidden auto;
}

.containerinfouserpopup .containerinfouser {
    width: calc(40% - 10px);
    height: calc(100% - 5px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #F2F2F2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    justify-content: space-between;
    padding: 5px;
    gap: 10px;
}

.containerinfouserpopup .containerinfouser .infouser {
    width: 100%;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
}

.containerinfouserpopup .containerinfouser .infouser .username {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    justify-content: flex-start;
}

.containerinfouserpopup .containerinfouser .infouser .username img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

.containerinfouserpopup .containerinfouser .infouser .username .picture {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerinfouserpopup .containerinfouser .infouser .username .picture svg {
    width: 100%;
    height: 100%;
}

.containerinfouserpopup .containerinfouser .infouser .username div {
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
}

.containerinfouserpopup .containerinfouser .infouser .username div p {
    font-size: 15px;
    font-weight: 400;
    max-width: 95%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerinfouserpopup .containerinfouser .infouser .username div input {
    height: 30px;
    border: 1px solid #CECECE;
    outline: none;
    max-width: 200px;
    width: 100%;
    font-size: 13px;
}

.containerinfouserpopup .containerinfouser .infouser .useremail {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerinfouserpopup .containerinfouser .infouser .useremail p {
    font-size: 15px;
    margin: 0 auto;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.containerinfouserpopup .containerinfouser .infouser .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    gap: 10px;
}

.containerinfouserpopup .containerinfouser .infouser .info p {
    font-size: 13px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerinfouserpopup .containerinfouser .infouser .info .infotitle {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
}

.containerinfouserpopup .containerinfouser .infouser .info .infotitle p {
    font-weight: 500;
    height: auto;
    max-width: 80%;
    text-align: left;
    overflow-wrap: break-word;
}

.containerinfouserpopup .containerinfouser .infouser .info li {
    width: 100%;
    height: auto;
}

.containerinfouserpopup .containerinfouser .infouser .info ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    gap: 5px;
    width: 100%;
    height: auto;
    padding-left: 15px;
}

.containerinfouserpopup .containerinfouser .infouser .info ul li {
    width: 100%;
    height: 30px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
}

.containerinfouserpopup .containerinfouser .infouser .info ul li input,
.containerinfouserpopup .containerinfouser .infouser .info ul li select,
.containerinfouserpopup .containerinfouser .infouser .useremail input {
    height: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    border: 1px solid #CECECE;
    border-radius: 5px;
    width: 100%;
    font-size: 13px;
}

.containerinfouserpopup .containerinfouser .infouser .info ul li select {
    cursor: pointer;
}

.containerinfouserpopup .containerinfouser .infouser .info ul li input,
.containerinfouserpopup .containerinfouser .infouser .info ul li select {
    width: 200px;
}

.containerinfouserpopup .buttonswitch,
.containerinfouserpopup .buttonswitchshared {
    width: 70px;
    background-color: #38A3A5;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-radius: 15px;
    transition: 0.5s ease;
    border: none;
}

.containerinfouserpopup .buttonswitch {
    height: 100%;
}

.containerinfouserpopup .buttonswitchshared {
    height: 30px;
}

.containerinfouserpopup .buttonswitchball {
    background-color: #FFFFFF;
    height: 22px;
    width: 26px;
    border-radius: 15px;
}

.containerinfouserpopup .active {
    padding-left: 40px;
}

.containerinfouserpopup .inactive {
    color: #ddd;
    font-family: 400;
    background-color: #CECECE;
}

.containerinfouserpopup .containerinfouser .infouser .info ul li .disabled {
    color: #ddd;
    font-family: 400;
    background-color: #CECECE;
    cursor: default;
}

.containerinfouserpopup .containerinfouser .infouser .modifiedfield {
    font-weight: 600;
    background-color: #97b8ce;
}


.containerinfouserpopup .containerinfouser .infouser .info ul li p {
    font-size: 13px;
}

.containerinfouserpopup .containerinfouser .buttons {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.containerinfouserpopup .containerinfouser .buttons button {
    height: 30px;
    border: none;
    cursor: pointer;
    background-color: #22577a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 20px;
}

.containerinfouserpopup .containerinfouser .buttons button:hover {
    background-color: #2f729e;
}

.containerinfouserpopup .containerinfouser .buttons .canceledit {
    background-color: #EB5757;
}

.containerinfouserpopup .containerinfouser .buttons .canceledit:hover {
    background-color: #e27575;
}

.containerinfouserpopup .containerinfouser .buttons .disabledsave,
.containerinfouserpopup .containerinfouser .buttons .disabledsave:hover {
    background-color: #CECECE;
    cursor: default;
}

.containerinfouserpopup .containerinfouser .buttons button p,
.containerinfouserpopup .containerlogsuser header button p,
.containerinfouserpopup .containerlogsuser .infotable .creatinginfo .buttonadd p {
    font-size: 14px;
    text-transform: uppercase;
    color: #FDFDFD;
    font-weight: 500;
}

.containerinfouserpopup .containerlogsuser {
    width: calc(60% - 10px);
    height: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.containerinfouserpopup .containerlogsuser header {
    width: 100%;
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #FDFDFD;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
    justify-content: space-around;
    z-index: 1;
}

.containerinfouserpopup .containerlogsuser header select {
    height: 30px;
    width: 200px;
    outline: none;
    border: 1px solid #CECECE;
    border-radius: 8px;
    padding-left: 10px;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 20px;
}

.containerinfouserpopup .containerlogsuser header button,
.containerinfouserpopup .containerlogsuser .infotable .creatinginfo .buttonadd {
    height: 30px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #22577a;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.containerinfouserpopup .containerlogsuser header button:hover,
.containerinfouserpopup .containerlogsuser .infotable .creatinginfo .buttonadd:hover {
    background-color: #2f729e;
}

.containerinfouserpopup .containerlogsuser .buttondisabled,
.containerinfouserpopup .containerlogsuser .buttondisabled:hover,
.containerinfouserpopup .containerlogsuser .infotable .creatinginfo .buttondisabled,
.containerinfouserpopup .containerlogsuser .infotable .creatinginfo .buttondisabled:hover {
    background-color: #CECECE;
    cursor: default;
}

.containerinfouserpopup .containerlogsuser header div {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerinfouserpopup .containerlogsuser header div p {
    font-size: 14px;
    font-weight: 500;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerinfouserpopup .containerlogsuser .infotable {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    z-index: 0;
    justify-content: flex-end;
    gap: 10px;
}

.containerinfouserpopup .containerlogsuser .infotable .table {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDFDFD;
    z-index: 1;
    transition: 1s;
}

.containerinfouserpopup .containerlogsuser .infotable .maxwidthtable {
    height: 100%;
}

.containerinfouserpopup .containerlogsuser .infotable .creatinginfo {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    transition: 1s;
    align-items: flex-start;
    padding: 0px 5px;
    gap: 10px;
    z-index: 0;
    flex-wrap: wrap;
}

.containerinfouserpopup .containerlogsuser .infotable .maxwidthinfo {
    height: 150px;
    animation: init 1s linear;
}

@keyframes init {
    from {
        transform: translateX(500px);
    }

    to {
        transform: translateX(0);
    }
}

.containerinfouserpopup .containerlogsuser .infotable .projectfield {
    width: auto;
    height: auto;
    z-index: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.containerinfouserpopup .containerlogsuser .infotable .projectfield label,
.containerinfouserpopup .containerlogsuser .infotable .projectfield input {
    font-size: 14px;
    font-weight: 400;
}

.containerinfouserpopup .containerlogsuser .infotable .projectfield input {
    border-radius: 8px;
    outline: none;
    border: 1px solid #CECECE;
    padding: 5px 0px 5px 10px;
}

@media (max-width: 1500px) {

    .containerinfouserpopup,
    .isloading {
        width: 90vw;
    }
}

@media (max-width: 1150px) {

    .containerinfouserpopup {
        gap: 10px;
    }

    .containerinfouserpopup .containerinfouser {
        width: calc(40% - 5px);
    }

    .containerinfouserpopup .containerlogsuser {
        width: calc(60% - 5px);
    }

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 150px;
    }
}

@media (max-width: 1000px) {

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 120px;
    }
}

@media (max-width: 880px) {

    .containerinfouserpopup .containerlogsuser .infotable input {
        width: 180px;
    }
    
}

@media (max-width: 750px) {

    .containerinfouserpopup {
        gap: 20px;
        overflow: auto;
        flex-wrap: wrap;
        padding: 0px 10px;
    }

    .containerinfouserpopup .containerinfouser,
    .containerinfouserpopup .containerlogsuser {
        width: 100%;
    }

    .containerinfouserpopup .containerinfouser {
        height: auto;
    }

    .containerinfouserpopup .containerlogsuser {
        height: auto;
        max-height: 600px;
    }

    .containerinfouserpopup .containerinfouser .infouser .username div {
        height: 70px;
    }

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 250px;
    }

    .containerinfouserpopup .containerlogsuser .infotable {
        height: 520px;
    }

    .containerinfouserpopup .containerlogsuser .infotable .creatinginfo {
        height: auto;
    }

    .containerinfouserpopup .containerlogsuser .infotable .table {
        height: 300px;
        min-height: 300px;
    }

    .containerinfouserpopup .containerlogsuser .infotable .maxwidthtable {
        height: 100%;
    }

    .containerinfouserpopup .containerinfouser .infotable .maxwidthinfo {
        height: 150px;
    }

    .containerinfouserpopup .containerlogsuser .infotable input {
        width: 250px;
    }
}

@media (max-width: 600px) {
    .containerinfouserpopup {
        gap: 10px;
        width: 80vw;
    }

    .isloading {
        width: 80vw;
    }

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 200px;
    }

    .containerinfouserpopup .containerlogsuser header select {
        width: 150px;
    }

    .containerinfouserpopup .containerlogsuser header div {
        width: calc(100% - 160px);
    }

    .containerinfouserpopup .containerlogsuser header select,
    .containerinfouserpopup .containerlogsuser header div p {
        font-size: 13px;
    }
}

@media (max-width: 450px) {

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 150px;
    }

    .containerinfouserpopup .containerlogsuser header select {
        width: 130px;
    }

    .containerinfouserpopup .containerlogsuser header div {
        width: calc(100% - 140px);
    }

    .containerinfouserpopup .containerlogsuser header select,
    .containerinfouserpopup .containerlogsuser header div p {
        font-size: 12px;
    }
}

@media (max-width: 380px) {

    .containerinfouserpopup .containerinfouser .infouser .info ul li input,
    .containerinfouserpopup .containerinfouser .infouser .info ul li select {
        width: 120px;
    }
}