#linkPopup {
    width: 18rem;
    height: auto;

    display: inline-flex;
    padding: 0.8rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5625rem;
    color: #282828;
    /* Headline (info paragraphs) */
    font-size: 1rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 1.28571rem;
    letter-spacing: 0.01429rem;
}

#linkPopup #options {
    height: 90%;
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

#linkPopup #options #option {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    width: fit-content;
}

#linkPopup #options #option .div-name {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
}

#linkPopup #options #checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #282828;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

#linkPopup #buttons {
    display: flex;
}


#linkPopup #buttons a {
    height: auto;
}