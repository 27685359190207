.project-card {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: column;
    background-color: #FDFDFD;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 212px;
    height: auto;
    font-weight: 400;
    margin: 10px;
    color: #282828;
    overflow: visible;
}

.project-card.background {
    width: auto;
}

.project-card.background.selected {
    border: 2px solid #007bff;
}

.project-card:hover,
.dataset-card:hover,
.model-card:hover {
    cursor: pointer;
    background: #E5E5E5;
}

.project-card .CardHeader {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.project-card #title {
    font-weight: 500;
    display: block;
    justify-content: flex-start;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 16px;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-card #more {
    background-color: aqua;
    padding-top: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
}

.project-card .Description {
    display: block;
    font-weight: 400;
    padding-left: 15px;
    padding-top: 7px;
    text-align: start;
    padding-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-card .Info {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-around;
}

.project-card .Info #number {
    font-weight: 700;
    font-size: 18px;
}

.project-card .LastUsed {
    display: flex;
    text-align: left;
    padding-top: 10px;
    padding-left: 15px;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
}

.project-card .LastUsed #icon {
    display: flex;
    justify-content: flex-start;
}

.project-card .LastUsed #text {
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    margin-left: 10px;
}

.dataset-card {
    outline: none;
    width: 328px;
    background-color: #FDFDFD;
    color: #282828;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 18px 5px 18px 18px;
    margin: 10px;
    font-size: 10px;
    letter-spacing: 0.2px;
    border: none;
    cursor: pointer;
    position: relative;
}

.dataset-card .dataset-checkbox {
    position: absolute;
    top: 10px;
    left: 5px;
}

.dataset-card .dataset-card-content {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
}

.dataset-card .dataset-card-content .dataset-card-icon,
.model-card .model-card-icon {
    height: 100%;
}

.dataset-card .dataset-card-content .dataset-card-info,
.model-card .model-card-info {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;
}

.dataset-card .dataset-card-content .dataset-card-info header,
.model-card .model-card-info header {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dataset-card .dataset-card-content .dataset-card-info header p,
.model-card .model-card-info header p {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 1.6em;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dataset-card .dataset-card-content .dataset-card-info p,
.model-card .model-card-info p {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dataset-card .dataset-card-content .dataset-card-info header .more-options,
.model-card .model-card-info header .more-options {
    margin: 0;
    padding: 0px;
    width: 25px;
    max-width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataset-card .dataset-card-content .dataset-card-info .dataset-card-date,
.model-card .model-card-info .model-card-date {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.dataset-card .dataset-card-content .dataset-card-info .dataset-card-date p,
.model-card .model-card-info .model-card-date p {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 13px;
    font-family: Montserrat, sans-serif;
}

.dataset-card .dataset-card-link {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.dataset-card .dataset-card-link .dataset-card-projects {
    margin: 0;
    padding: 0;
    width: 75%;
    display: flex;
    align-items: center;
}

.dataset-card .dataset-card-link .dataset-card-projects p {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    justify-content: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dataset-card .dataset-card-link .dataset-card-projects p span {
    margin: 0;
    padding: 0;
    height: 100%;
    padding-right: 5px;

}

.dataset-card .dataset-card-link .dataset-card-projects p span:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
}

.model-card {
    outline: none;
    width: 328px;
    background-color: #FDFDFD;
    color: #282828;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    padding: 18px;
    margin: 10px;
    font-size: 10px;
    letter-spacing: 0.2px;
    border: none;
    cursor: pointer;
    justify-content: space-between;
}

#shared-with {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 200px;
}

@media (max-width: 600px) {

    .dataset-card,
    .model-card {
        width: 260px;
        padding: 25px 20px 10px;
    }

    .dataset-card .dataset-card-content .dataset-card-info,
    .model-card .model-card-info {
        width: 70%;
    }

    .dataset-card .dataset-card-content .dataset-card-icon svg,
    .model-card .model-card-icon svg {
        width: 50px;
        height: 50px;
    }

    .dataset-card .dataset-card-content .dataset-card-info .dataset-card-date,
    .model-card .model-card-info .model-card-date {
        margin-top: 8px;
    }

    .dataset-card .dataset-card-content .dataset-card-info .dataset-card-date p,
    .model-card .model-card-info .model-card-date p {
        font-size: 0.8em;
    }
}