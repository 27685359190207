.default {
    display: flex;
    flex-direction: row;
    width: 212px;
    align-items: center;
}

.small {
    display: inline-block;
    border-radius: 10px;
}

.trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    font-weight: 700;
    color: rgb(207, 0, 0)
}

.trigger .ic {
    margin-left: 7px;
}

.Input {
    font-size: 14px;
    padding-right: 10px;
    width: 327px;
}

.Sort {
    display: flex;
    width: 180px;
}

.error-text {
    /* color: rgb(207, 0, 0); */
    color: rgb(207, 0, 0);
}