.result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%
}

#kmeansd3-result {
    width: 100%;
    height: 96%;
}