:root {
    --width: 350px;
}

.containerDeleteGenericPopup{
    width: var(--width);
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    line-height: 34px;
    letter-spacing: 0.2px;
    margin: 0px;
    padding: 0px;
    font-style: normal;
}

.containerDeleteGenericPopup p {
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.containerDeleteGenericPopup .containerButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
    padding: 0px;
    align-items: center;
    justify-content: space-evenly;
}