
.notification-popup {
    min-width: 200px;
    height: 50vh;
    width: auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.notification-item {
    width: 200px;
    max-width: 200px;
    height: 103px;
    max-height: 103px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5e5e5;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    transition: 0.8s;
}

.notification-item:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.notification-item .ni--header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.notification-item .ni--header .ni--header--icon {
    margin-right: 5px;
}

.notification-item .ni--header .ni--header--icon {
    color: #38A3A5;
    size: 16;
}


.notification-item .ni--body .ni--body--name {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    margin-bottom: 6px;
}

.notification-item .ni--body .ni--body--project {
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
}

.notification-item .ni--footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}

.notification-item .ni--footer .ni--footer--date {
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.20000000298023224px;
    text-align: right;
}

.alert-icon {
    font-family: Montserrat;
    font-size: 14px;
    margin: auto;
}