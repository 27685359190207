.container-historycard {
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 400;
    margin: 10px;
    color: #282828;
    width: 100%;
    height: 100px;
    font-size: 10px;
}

.container-historycard .container-content {
    width: 100%;
    height: 70px;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
}

.container-historycard .container-content .container-svg {
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-historycard .container-content .container-svg svg {
    width: 45px;
    height: 45px;
}

.container-historycard .container-content .container-info {
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

}

.container-historycard .container-content .container-info div {
    margin: 0;
    padding: 0;
}

.container-historycard .container-content .container-info #name {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.container-historycard .container-content .container-info span {
    font-size: 1em;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: start;
}

.container-historycard .container-content .container-info span p {
    font-size: 1.1em;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container-historycard .container-content .container-info #date {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}

.container-historycard .container-content .container-info #date svg {
    width: 15px;
    height: 15px;
}


.container-historycard .container-button {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-historycard .container-button button {
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #bebebe;
    margin: 0;
    padding: 0;
    color: #22577a;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 16px;
}

.container-historycard .container-button div {
    border-top: 1px solid #bebebe;
    width: 80%;
    height: 100%;
    padding: 0px 24px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.container-historycard .container-button div button {
    width: 32%;
    height: 80%;
    border: none;
    color: #22577a;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-historycard .container-button .buttonView {
    color: #22577a;
}

.container-historycard .container-button .buttonCancel {
    color: #EB5757;
    font-size: 1.05em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.container-historycard .container-button .buttonCancel p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #EB5757;
}

.container-historycard .container-button .buttonUndefined {
    color: #ececec;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.popup-inner .close-btn {
    width: auto;
}

.container-history main section .undefined {
    cursor: default;
    background-color: #e9e9e9;
    width: 300px;
}

.container-history main section .containerButton span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50px;
}

.container-history main section .containerButton button {
    justify-content: center;
    background-color: #22577a;
    color: #FDFDFD;
    margin: 0;
    padding: 0;
}

.popup .container-modelpopup {
    width: 300px;
    max-height: 300px;
    overflow: hidden auto;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
    background-color: #FDFDFD;
}

.popup .container-modelpopup .info-top {
    width: 100%;
    height: 135px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.popup .container-modelpopup .info-top svg {
    width: 35%;
    height: 100%;
}

.popup .container-modelpopup .info-top .content-top {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    justify-content: flex-start;
}

.popup .container-modelpopup .info-top .content-top div {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 10px;
}

.popup .container-modelpopup .info-top .content-top div span,
.popup .container-modelpopup .info-bottom div span {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    font-size: 1.1em;
}

.popup .container-modelpopup .info-top .content-top div span p,
.popup .container-modelpopup .info-bottom div span p {
    font-size: 1.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.popup .container-modelpopup .info-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.popup .container-modelpopup .info-bottom div {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-size: 10px;
}

.popup .container-modelpopup .info-bottom .date {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 1.3em;
    font-weight: 500;
}

.popup .container-modelpopup .info-bottom .buttons {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.popup .container-modelpopup .info-bottom .buttons button {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup .container-modelpopup .info-bottom .buttons #open {
    background-color: #38A3A5;
    border-radius: 8px;
    color: #FDFDFD;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 16px;
}

.popup .container-modelpopup .info-bottom .buttons #open:hover{
    background-color: #3dacad;
}