.generated-predictions p,
.generated-predictions span {
    font-family: Montserrat;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.2px;
    line-height: 15px;
    color: #282828;
    font-size: 10px;
    padding: 0;
    margin: 0;
}

.generated-predictions div {
    padding: 0;
    margin: 0;
}

.generated-predictions  {
    width: 700px;
    height: 600px;
    display: flex;
    flex-direction: column;
}

.generated-predictions header {
    width: 100%;
    height: 50px;
    text-align: left;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
}

.generated-predictions header p {
    font-size: 24px;
    line-height: 34px;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 95%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.generated-predictions .sections {
    width: 100%;
    height: calc(600px - 50px);
    display: flex;
    flex-direction: row;
}

.generated-predictions .sections .generated-predictions-info {
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #F2F2F2;
    border-radius: 10px;
    justify-content: space-between;
}

.generated-predictions .sections .generated-predictions-info .infos {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
}

.generated-predictions .sections .generated-predictions-info button {
    width: 100%;
    height: 40px;
    background-color: #22577A;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    border: none;
}

.generated-predictions .sections .generated-predictions-info button:hover {
    background-color: #2f729e;
}

.generated-predictions .sections .generated-predictions-info button p {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
}

.generated-predictions .sections .generated-predictions-info .infos div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.generated-predictions .sections .generated-predictions-info .infos div p,
.generated-predictions .sections .generated-predictions-info .infos div span {
    width: auto;
    height: auto;
    font-size: 15px;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 2px;
}

.generated-predictions .sections .generated-predictions-info .infos div span {
    font-weight: 500;
}

.generated-predictions .sections .generated-predictions-predictions {
    height: 100%;
    width: calc(700px - 250px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
    overflow: hidden auto;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
    margin: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 0.5px solid #2e2e2e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction header p {
    width: auto;
    height: auto;
    font-size: 14px;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px;
    margin: 0px;
    line-height: 20px;
    font-weight: 500;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction header button {
    display: flex;
    cursor: pointer;
    width: auto;
    height: 100%;
    max-width: 20%;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction .card-result {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction .card-result p {
    max-width: 100%;
    width: auto;
    height: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    padding: 2px;
    display: flex;
    align-items: center;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction button {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    cursor: pointer;
    border: none;
    outline: none;
}

.generated-predictions .sections .generated-predictions-predictions .card-prediction button p {
    font-weight: 500;
    font-size: 14px;
    color: #22577A;
}

@media (max-width: 750px) {
    .generated-predictions  {
        width: 500px;
        overflow: hidden auto;
    }
} 

@media (max-width: 600px) {
    .generated-predictions  {
        width: 300px;
        height: 500px;
        overflow: hidden auto;
    }

    .generated-predictions .sections {
        height: auto;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0px;
    }

    .generated-predictions .sections .generated-predictions-info {
        height: auto;
        width: 100%;
        gap: 20px;
        padding: 10px;
    }

    .generated-predictions .sections .generated-predictions-info div {
        gap: 5px;
    }
    
    .generated-predictions .sections .generated-predictions-predictions {
        height: auto;
        width: 100%;
        padding: 0px;
        overflow: hidden;
    }
}
