.containerButtonSwitch {
    width: 29px;
    height: 18px;
    cursor: pointer;
    border-radius: 100px;
    transition: 0.5s;
    display: flex;
    align-items: center;
    padding: 0 2px 0 2px;
}

.containerButtonSwitch div {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
}

.buttonActive {
    background-color: #38A3A5;
    padding-left: 12px;
}

.buttonInactive{
    background: rgba(28, 27, 31, 0.12);
}