.userinput{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    width: 100%;
    margin-bottom: 24px;
}

.userinput .input-heading{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.userinput .input-container{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
}


