.mensagem-popup {
    min-width: 200px;
    max-width: 260px;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
}

.mensagem-popup .title {
    display: flex;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    height: auto;
    float: left;
    left: 20px;
    top: 20px;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 10px;
    text-align: start;
    line-break: strict;
}

.mensagem-popup .title #spacer {
    float: right; 
    width: 50px; 
    height: 25px;
}


.mensagem-popup #description {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    margin-top: 10px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

.mensagem-popup .buttons {
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

