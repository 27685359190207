.SearchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.Input {
    width: 327px;
    height: 80%;
}

.Sort {
    display: flex;
    width: 200px;
    height: 80%;
}

.ui.fluid.dropdown {
    display: flex !important;
    align-items: center;
    height: 96%;
}

@media (max-width: 1200px) {

    .SearchBar {
        font-size: 13px;
    }

    .SearchBar .Input {
        width: 250px;
    }

    .SearchBar .Sort {
        width: 150px;
    }
}

@media (max-width: 940px) {

    .SearchBar {
        font-size: 12px;
    }

    .SearchBar .Input {
        width: 200px;
    }

    .SearchBar .Sort {
        width: 150px;
    }
}

@media (max-width: 700px) {

    .SearchBar .Input {
        width: 150px;
    }

    .SearchBar .Sort {
        width: 120px;
    }

}
