.isloading {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.containerjava p,
.containerjava h2 {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
}

.containerjava {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.containerjava .containergraphic,
.containerjava .containerlogs {
    width: 80%;
}

.containerjava .containergraphic .graphic,
.containerjava .containergraphic .logo,
.containerjava .containerlogs .tablelogsspring,
.containerjava .containerlogs .tablelogspark {
    border-radius: 15px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.containerjava .containergraphic {
    height: 30%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.containerjava .containergraphic .graphic {
    width: calc(100% - 320px);
    height: 100%;
}

.containerjava .containergraphic .logo {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerjava .containergraphic .logo img {
    max-width: 90%;
    width: auto;
    max-height: 90%;
    height: auto;
}

.containerjava .containerlogs {
    height: 65%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.containerjava .containerlogs .tablelogsspring,
.containerjava .containerlogs .tablelogspark {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerjava .containerlogs .tablelogsspring header,
.containerjava .containerlogs .tablelogspark header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
    border-bottom: 1px solid #CECECE;
}

.containerjava .containerlogs .tablelogsspring header img,
.containerjava .containerlogs .tablelogspark header img {
    width: auto;
    height: auto;
    max-width: 40px;
    max-height: 40px;
}

.containerjava .containerlogs .tablelogsspring header h2,
.containerjava .containerlogs .tablelogspark header h2 {
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.containerjava .containerlogs .tablelogsspring .logs,
.containerjava .containerlogs .tablelogspark .logs {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden auto;
}

.containerjava .containerlogs .tablelogsspring .logs article,
.containerjava .containerlogs .tablelogspark .logs article {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding: 12px;
    border-bottom-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.containerjava .containerlogs .tablelogsspring .logs article span,
.containerjava .containerlogs .tablelogspark .logs article span {
    font-size: 14px;
    font-family: 500;
    color: #CECECE;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    height: auto;
}

.containerjava .containerlogs .tablelogsspring .logs button {
    width: 100%;
    background-color: transparent;
    outline: none;
    transition: 0.5s;
    cursor: pointer;
    padding: 5px 15px;
    align-items: center;
    border-bottom: 1px solid #CECECE;
    border-top: 1px solid #CECECE;
    border-right: none;
    border-left: none;
}

.containerjava .containerlogs .tablelogsspring .logs button:hover {
    background-color: #CECECE;
}

.containerjava .containerlogs .tablelogsspring .logs button p {
    display: flex;
    text-align: left;
    font-size: 11px;
    font-weight: 300;
}

.containerjava .logspringpopup {
    width: 700px;
    height: auto;
    min-height: 300px;
    max-height: 500px;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    text-align: left;
    padding: 8px;
}

.containerjava .logspringpopup p {
    width: auto;
    height: auto;
    font-size: 13px;
    font-weight: 300;
    word-break: break-all;
}

@media (max-width: 1200px) {

    .containerjava .containergraphic .graphic {
        width: calc(100% - 250px);
    }

    .containerjava .containergraphic .logo {
        width: 250px;
    }
}

@media (max-width: 1000px) {

    .containerjava {
        gap: 15px;
    }

    .containerjava .containergraphic,
    .containerjava .containerlogs {
        width: 96%;
    }

    .containerjava .containergraphic .graphic {
        width: calc(100% - 200px);
    }

    .containerjava .containergraphic .logo {
        width: 200px;
    }
}

@media (max-width: 800px) {

    .containerjava .containergraphic,
    .containerjava .containerlogs {
        width: 100%;
    }

    .containerjava .containergraphic .graphic {
        width: calc(100% - 150px);
    }

    .containerjava .containergraphic .logo {
        width: 150px;
    }
}

@media (max-width: 750px) {
    .containerjava .logspringpopup {
        width: 500px;
        max-height: 600px;
    }
}

@media (max-width: 550px) {
    .containerjava .logspringpopup {
        width: 350px;
        max-height: 600px;
    }
}

@media (max-width: 500px) {

    .containerjava {
        z-index: 0;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden auto;
        margin-top: 3.5px;
    }

    .containerjava .containergraphic {
        width: 100%;
        height: auto;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .containerjava .containergraphic .graphic {
        width: 100%;
        height: 300px;
    }

    .containerjava .containergraphic .logo {
        width: 100%;
        height: 200px;
        max-width: 100%;
    }

    .containerjava .containerlogs {
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .containerjava .containerlogs .tablelogsspring,
    .containerjava .containerlogs .tablelogspark {
        width: 100%;
        height: auto;
        max-height: 400px;
    }

    /* .containermongodb .containerlogs .tablelogs .date,
    .containermongodb .containerlogs .tablelogs .log  {
        font-size: 11px;
    }

    .containermongodb .containerlogs .tablelogs .logs button {
        height: 40px;
    }

    .containermongodb .containerlogs .tablelogs .logs button p {
        font-size: 10px;
    } */
}

@media (max-width: 400px) {
    .containerjava .logspringpopup {
        width: 320px;
        max-height: 500px;
    }
}