.popup-inner .histogram-popup {
    padding: 20px;
}

.histogram-popup p {
    font-family: Montserrat;
    padding: 0;
    margin: 0;
    font-style: normal;
    letter-spacing: 0.2px;
}


.histogram-popup {
    width: 379px;
    height: 513px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.histogram-popup header {
    width: 100%;
    height: 30px;
    gap: 15px;
    display: flex;
    align-items: center;
    font-size: 10px;
}

.histogram-popup header p {
    max-width: 80%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2em;
    line-height: 18px;
    font-weight: 400;
}

.histogram-popup table {
    width: 100%;
    max-height: calc(100% - 50px);
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

.histogram-popup table thead {
    position: sticky;
    top: 0px;
    border-bottom: 1px solid #282828;
    background-color: #eeeeee;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.histogram-popup table tbody, 
.histogram-popup table thead {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 5px;
}

.histogram-popup table tbody tr,
.histogram-popup table thead tr {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
}

.histogram-popup table tbody tr th,
.histogram-popup table thead tr th {
    display: flex;
    align-items: center;
    width: 50%;
    height: auto;
    justify-content: center;
}

.histogram-popup table tbody tr th p,
.histogram-popup table thead tr th p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    max-width: 90%;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 15px;
}

.histogram-popup table thead tr th p {
    font-weight: 700;
}

@media (max-width: 500px) {

    .histogram-popup {
        width: 330px;
    }

}