.containerattemplimit p,
.containerattemplimit h2 {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    padding: 0;
    margin: 0;
}

.isloading {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.containerattemplimit {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.containerattemplimit .buttons {
    width: 80%;
    max-height: 10%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.containerattemplimit .buttons .button {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: min-content;
    background-color: #22577a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 20px;
    border: none;
    outline: none;
    margin: 0;
    cursor: pointer;
    flex-direction: row;
    gap: 10px;
}

.containerattemplimit .buttons .button:hover {
    background-color: #2f729e;
}

.containerattemplimit .buttons .buttondisabled,
.containerattemplimit .buttons .buttondisabled:hover {
    background-color: #CECECE;
    cursor: default;
}

.containerattemplimit .buttons .button p {
    font-size: 13px;
    text-transform: uppercase;
    color: #FDFDFD;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerattemplimit .table{
    width: 80%;
    height: 90%;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #FDFDFD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerattemplimit .button svg {
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
}

.containerattemplimit .button svg path {
    fill: white;
}

@media (max-width: 1350px) {

    .containerattemplimit {
        padding: 10px;
    }


    .containerattemplimit .table,
    .containerattemplimit .buttons {
        width: 90%;
    }

}

@media (max-width: 500px) {

    .containerattemplimit .table{
        width: 100%;
        height: 100%;
    }

}