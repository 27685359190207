.monitoring p,
.monitoring header .headertitle .buttons select {
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    letter-spacing: 0.2px;
}


.monitoring {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: Montserrat;
    background: url("../../images/backgroubd_myprojects_1376.svg");
    background-position: center center;
    background-size: cover;
}

.monitoring header {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.monitoring header .headertitle {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 50px;
    justify-content: space-between;
}

.monitoring header .headertitle .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    max-width: 80%;
}

.monitoring header .headertitle .title p {
    font-size: 25px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding: 3px;
    font-weight: 500;
}

.monitoring header .headertitle .buttons {
    height: 100%;
    padding: 0;
    margin: 0;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.monitoring header .headertitle .buttons button {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    background-color: #22577a;
}

.monitoring header .headertitle .buttons button:hover {
    background-color: #2f729e;
}

.monitoring header .headertitle .buttons button p {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.monitoring header .headertitle .buttons button {
    width: auto;
    height: auto;
    max-width: 100px;
    cursor: pointer;
}


.monitoring header nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25px;
    padding: 0;
    margin: 0;
}

.monitoring header nav ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    padding: 0;
    margin: 0;
    gap: 30px
}

.monitoring header nav ul li {
    height: auto;
    width: auto;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.monitoring header nav ul button {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: 0.5s;
    padding-bottom: 10px;
}

.monitoring header nav ul li button:hover {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(44, 194, 240);
}

.monitoring header nav ul button p {
    font-size: 13px;
    font-weight: 500;
    transition: 0.5s;
}

.monitoring header nav ul li button:hover p {
    color: rgb(44, 194, 240)
}

@media (max-width: 800px){

    .monitoring header .headertitle {
        padding: 0px 40px;
    }
}

@media (max-width: 700px){

    .monitoring header .headertitle {
        padding: 0px 25px;
    }

    .monitoring header .headertitle .title p {
        font-size: 20px;
    }
}

@media (max-width: 600px){

    .monitoring header .headertitle {
        padding: 0px 15px;
    }

    .monitoring header .headertitle .title p {
        font-size: 18px;
    }

    .monitoring header .headertitle button {
        padding: 8px 15px;
    }

    .monitoring header .headertitle button p {
        font-size: 11px;
        font-weight: 600;
    }

    .monitoring header nav ul {
        gap: 20px
    }
}

@media (max-width: 500px){

    .monitoring header .headertitle {
        padding: 0px 10px;
    }

    .monitoring header .headertitle .title {
        flex-direction: column;
        align-items: flex-start;
        max-width: 80%;
    }

    .monitoring header .headertitle .title p {
        font-size: 20px;
        letter-spacing: 0.5px;
    }

    .monitoring header nav ul button p {
        font-size: 12px;
    }
}

@media (max-width: 400px){

    .monitoring header .headertitle .title {
        flex-direction: column;
        align-items: flex-start;
        max-width: 77%;
    }

    .monitoring header .headertitle .title p {
        font-size: 15px;
        letter-spacing: 0.5px;
        padding: 3px;
    }

    .monitoring header .headertitle button {
        padding: 6px 10px;
    }

    .monitoring header .headertitle button p {
        font-size: 10px;
        font-weight: 500;
    }

    .monitoring header nav ul {
        gap: 5px
    }

    .monitoring header nav ul button p {
        font-size: 11px;
    }
}