.containerSuccessfullyCreatedKeyApi {
    width: 373px;
    height: 213px;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: #282828;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
}

.containerTitlePopup{
    width: inherit;
    height: 34px;
    font-size: 24px;
    line-height: 34px;
    display: flex;
    align-items: center;
}

.containerKey {
    width: inherit;
    height: 30px;
    background: #F2F2F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    line-height: 18px;
    animation: alert 1s infinite alternate;
}

@keyframes alert {
    from {
        background-color: #fb7b79;
        color: #eee;
    }
    to {
        background-color: #F2F2F2;
        color: #282828;
    }
}

.containerKey p {
    width: 271px;
    height: 18px;
    display: flex;
    align-items: center;
    margin: auto auto 0px 0px;
    overflow: hidden;
}

.containerKey button {
    width: 30px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.containerContent {
    width: inherit;
    margin: 0px 0px auto auto;
    height: 45px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerButton {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    padding-left: 110px;
    gap: 10px;
}

.containerButton button {
    height: inherit;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
}

#buttonClose {
    width: 102px;
    border: none;
    background-color: transparent;
    color: #22577A;
}
#buttonImplement {
    width: 150px;
    background: #22577A;
    border: none;
    border-radius: 8px;
    color: #FDFDFD;
}