.containerApiKey {
    width: 680px;
    font-family: "Montserrat";
    font-style: normal;
    letter-spacing: 0.2px;
}

.containerHeader {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-style: normal;
}

.containerHeader p {
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    color: #282828
}

.containerHeader span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.containerContent-ApiKeyPopup {
    margin-left: 12px;
    margin-top: 24px;
}

.headerContent {
    width: 640px;
    height: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
}

.headerContent p,
.containerKey-ApiKeyPopup div p {
    height: inherit;
    display: flex;
    margin: 0 12px;
    align-items: center;
}


.containerContent-ApiKeyPopup #name {
    width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#use {
    width: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#status {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 66px;
}

#creation {
    width: 80px;
}

#validity {
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
}

.containerKey-ApiKeyPopup {
    width: 100%;
    height: 126px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    cursor: pointer;
}

/* .containerApiKey .containerContent-ApiKeyPopup .noKeyApi{
    width: 100%;
    height: 126px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding: 50px;
    font-size: 12px;
    font-weight: 400;
    color: #282828;
} */

.containerKey-ApiKeyPopup div {
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    transition: 0.3s;
}

.containerKey-ApiKeyPopup div:hover {
    background-color: #F2F2F2;
}

.containerKey-ApiKeyPopup div #edit{
    width: 98px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 0px auto -25px;
}

.containerKey-ApiKeyPopup div #edit div{
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#active,
#inactive {
    width: 67px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-weight: 500;
    color: #FDFDFD;
}

#active {
    background: #38A3A5;
}

#inactive {
    background: #808080;
}

.buttonNewKeyApi{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #22577A;
    margin-top: 24px;
    width: 194px;
    height: 32px;
    background-color: transparent;
    border: 1px solid #22577A;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.containerKeySelected {
    background-color: #F2F2F2;
}