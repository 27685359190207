.container-quickstartpopup p,
.container-quickstartpopup span,
.container-quickstartpopup h3,
.container-quickstartpopup article,
.container-quickstartpopup div,
.container-quickstartpopup button {
    padding: 0;
    margin: 0;
    color: #282828
}

.container-quickstartpopup button {
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    color: #282828;
    display: flex;
    align-items: start;
}

.container-quickstartpopup {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 650px;
    background-color: transparent;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.2px;
    padding: 0px 16px;
}

.container-quickstartpopup header {
    width: 100%;
    height: 16%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.container-quickstartpopup header h3 {
    width: 100%;
    /* height: 100%; */
    font-weight: 700;
    font-size: 1.8em;
    line-height: 18px;
    width: 100%;
    justify-content: center;
    text-align: left;
}

.container-quickstartpopup main {
    width: 100%;
    height: 76.2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.container-quickstartpopup main article {
    width: 100%;
    font-size: 1.3em;
    text-align: left;
    height: 20%;
}

.container-quickstartpopup main video {
    width: 100%;
    max-height: 70%;
    cursor: pointer;
}

.container-quickstartpopup footer {
    width: 100%;
    height: 7.8%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.container-quickstartpopup footer div,
.container-quickstartpopup footer span {
    font-size: 1.2em;
}

.container-quickstartpopup footer span {
    color: #808080;
}

.container-quickstartpopup footer div {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

@media (max-width: 1200px) {
    .container-quickstartpopup {
        height: 500px;
    }
}

@media (max-width: 940px) {
    .container-quickstartpopup {
        width: 500px;
        height: 500px;
        padding: 0px;
    }
}

@media (max-width: 740px) {
    .container-quickstartpopup {
        width: 400px;
        height: 450px;
    }
}

@media (max-width: 500px) {
    .container-quickstartpopup {
        width: 280px;
        height: 380px;
    }

    .container-quickstartpopup article {
        height: 15%;
    }

    .container-quickstartpopup video {
        max-height: 90%;
        height: 100%;
    }
}