.container-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:transparent;
    width: 58%;
    margin: 100px auto 100px auto;
    font-size: 10px;
}

.container-contact .title {
    font-style: normal;
    font-weight: 700;
    font-size: 5em;
    line-height: 48px;
    color: #282828;
    text-align: left;
    margin-bottom: 50px;
}

.container-contact .wise{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
}

.container-contact .wise .logo{
    padding-left: 30px;
    margin-bottom: 50px;
    width: min-content;
}

.container-contact .wise .description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 50;
    font-size: 1.6em;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #282828;
    text-align: left;
}

.container-contact .wise .informations{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    text-align: left;
    font-style: normal;
    font-weight: 50;
    font-size: 1.6em;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #282828;
    padding: 10px 0px;
}

.container-contact .wise .informations .left{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
}

.container-contact .wise .informations .left div{
    padding-bottom: 10px;
}

.container-contact .wise .informations .right div{
    padding-bottom: 10px;
}

.container-contact .lapti{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    margin-top: 50px;
}

.container-contact .lapti .logo{
    padding-left: 30px;
    margin-bottom: 50px;
    width: min-content;
}

.container-contact .lapti .description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    text-align: left;
    font-style: normal;
    font-weight: 50;
    font-size: 1.6em;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #282828;
    text-align: left;
}

.container-contact .lapti .informations{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    text-align: left;
    text-align: left;
    font-style: normal;
    font-weight: 50;
    font-size: 1.6em;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #282828;
    padding: 10px 0px;
}

.container-contact .lapti .informations .left{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
}

.container-contact .lapti .informations .left div,a{
    padding-bottom: 10px;
}

.logo:hover
{
    cursor: pointer;
}

@media (max-width: 1200px){

    .container-contact{
        font-size: 11px;
        width: 65%;
    }

}

@media (max-width: 760px) {

    .container-contact{
        font-size: 12px;
        width: 80%;
    }

    .container-contact .title {
        font-size: 4em;
    }

    .container-contact .wise .logo{
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-contact .lapti .logo{
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}