.collaborative-filtering-table p {
    text-transform: uppercase;
}

.collaborative-filtering-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden auto;
    gap: 20px;
    padding: 20px;
}

.collaborative-filtering-table table {
    width: 200px;
    height: auto;
    padding: 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.collaborative-filtering-table table thead, 
.collaborative-filtering-table table tbody {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.collaborative-filtering-table table thead tr, 
.collaborative-filtering-table table tbody tr {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    padding: 3px 0px;
}
.collaborative-filtering-table table thead #user-id {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
}

.collaborative-filtering-table table thead #user-id span {
    width: 65%;
    height: 1px;
    background-color: #E5E5E5;
}

.collaborative-filtering-table table thead tr th, 
.collaborative-filtering-table table tbody tr th {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.collaborative-filtering-table table thead tr th p, 
.collaborative-filtering-table table tbody tr th p {
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.collaborative-filtering-table table thead tr th p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
}

.collaborative-filtering-table table tbody tr th p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}