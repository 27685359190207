.dataset {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Montserrat, sans-serif;
    text-align: left;
    margin-left: 1.5em;
    margin-top: 1.5em;
    overflow-y: auto;
}

.header {
    display: flex;
    align-items: center;
    gap: 16em;
    margin-top: 1em;
}

.infos {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 20%;
}

.infosDataSource {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 60%;
}

.datasetName {
    font-size: medium;
    font-weight: bold;
}

.headerButtons {
    display: flex;
    border-radius: 2em;
    background-color: #E5E5E540;
    margin-bottom: 0.5em;
}

.datasourceButtons {
    display: flex;
    gap: 1em;
}

.headerButtons button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.7em 5em;
    border-radius: 2em;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.headerButtons .activeButton {
    background-color: #38A3A51A;
}

.trashButton {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 18px;
    align-items: center;
    gap: 1rem;
}

.trashIcon {
    cursor: pointer;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    outline: none;
}

@media (max-width: 900px) {
    .header {
        gap: 1em;
    }

    .headerButtons button {
        padding: 0.7em 3em;
    }

}

@media(max-width:576px) {
    .dataset {
        max-width: 80vw;
        min-height: 100vh;
    }

    .header {
        max-width: 100%;
        gap: 1em;
    }

    .headerButtons button {
        padding: 0.7em 1em;
    }
}