:root {
    --primary: #22577a;
    --primary--hovered: #2f729e;
    --outline: #fdfdfd;
    --outline--hovered: #ececec;
    --disabled: #e0e0e0;
    --disabled--text: #f2f2f2;
    --cancel: #EB5757;
    --open: #38A3A5;
}

.dropdown select{
    background-color: transparent;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    width: 100%;
    padding: 10px 20px 10px 20px;
}

.dropdown option{
    background-color: var(--primary--hovered);
    color: var(--outline);
}