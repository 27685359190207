.container-choose-algorithm p,
.container-choose-algorithm div,
.container-choose-algorithm h1,
.container-choose-algorithm header,
.container-choose-algorithm button,
.container-choose-algorithm span,
.container-choose-algorithm main,
.container-choose-algorithm section {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    letter-spacing: 0.2px;
}

.container-choose-algorithm {
    width: calc(100vw * 0.73);
    height: calc(100vh * 0.65);
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #FDFDFD;
}

.container-choose-algorithm header {
    width: 100%;
    height: 6.17%;
    display: flex;
    align-items: center;
    font-size: 2.4em;
    font-weight: 300;
    line-height: 34px;
}

.container-choose-algorithm main {
    width: 100%;
    height: calc(100% - 9%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.container-choose-algorithm main .choose-algorithm {
    height: 100%;
    width: 20.68%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.container-choose-algorithm main .choose-algorithm section {
    width: 100%;
    height: auto;
}

.container-choose-algorithm main .choose-algorithm section .section-button {
    width: 100%;
    height: 34px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
    background-color: transparent;
    border: 1px solid #282828;
    border-radius: 8px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.container-choose-algorithm main .choose-algorithm section .section-button:hover {
    background-color: #F2F2F2;
}

.container-choose-algorithm main .choose-algorithm section .section-button p {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    height: 100%;
    align-items: center;
}

.container-choose-algorithm main .choose-algorithm section #supervised,
.container-choose-algorithm main .choose-algorithm section #unsupervised {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    align-items: start;
    gap: 10px;
}

.container-choose-algorithm main .choose-algorithm section #supervised button,
.container-choose-algorithm main .choose-algorithm section #unsupervised button {
    width: 100%;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #282828;
    border-radius: 8px;
    padding-left: 8px;
}

.container-choose-algorithm main .choose-algorithm section #supervised .selected,
.container-choose-algorithm main .choose-algorithm section #unsupervised .selected {
    background-color: #22577A;
    color: #FDFDFD;
}

.container-choose-algorithm main .choose-algorithm section #supervised button p,
.container-choose-algorithm main .choose-algorithm section #unsupervised button p {
    font-size: 1.2em;
    line-height: 18px;
    font-family: 400;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
}

.container-choose-algorithm main .model-representation {
    height: 100%;
    width: 27.89%;
    background-color: #E0E0E0;
}

.container-choose-algorithm main .container-info-execute {
    height: 100%;
    width: 25.22%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.container-choose-algorithm main .container-info-execute article {
    width: 100%;
    /* height: 43.82%; */
    height: auto;
    max-height: 55%;
    text-align: left;
    overflow-y: auto;
}

.container-choose-algorithm main .container-info-execute article p {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 15px;
    /* height: 85%; */
    height: auto;
    width: 100%;
}

.container-choose-algorithm main .container-info-execute article #info-title {
    overflow: hidden;
}

.container-choose-algorithm main .container-info-execute article header {
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 0;
    background-color: #FDFDFD;
}

.container-choose-algorithm main .container-info-execute article header p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.container-choose-algorithm main .container-info-execute article button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #4183c4;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    gap: 5px;
}

.container-choose-algorithm main .container-info-execute article button:hover {
    color: #22577A;

}

.container-choose-algorithm main .container-info-execute .choose-main-attribute {
    width: 100%;
    height: 43.82%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .dataset-name {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .dataset-name p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #22577A;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute select {
    max-width: 100%;
    width: 212px;
    height: 30px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #282828;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #282828;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .input {
    padding: 0;
    margin: 0;
    max-width: 100%;
    width: 212px;
    height: 55px;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .input header {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .input header p,
.container-choose-algorithm main .container-info-execute .choose-main-attribute .input input {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute .input input {
    width: 100%;
    height: 60%;
    outline: none;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 12px;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute a {
    width: 100%;
    height: auto;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute a button {
    background-color: transparent;
    background-color: #22577A;
    padding: 0;
    margin: 0;
    max-width: 100%;
    width: 157px;
    height: 100%;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    cursor: pointer;
}

.container-choose-algorithm main .container-info-execute .choose-main-attribute a button p {
    color: #FDFDFD;
    font-size: 13.5px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.container-choose-algorithm main .choose-attributes {
    height: 100%;
    width: 22.11%;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
}

.container-choose-algorithm main .choose-attributes .select-als {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.container-choose-algorithm main .choose-attributes .select-als p {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    height: 20px;
    display: flex;
    align-items: center;
}

.container-choose-algorithm main .choose-attributes .select-als select {
    width: 80%;
    height: 30px;
    background-color: transparent;
    outline: none;
    border: 1px solid #282828;
    border-radius: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

#select-algorithm-error {
    background-color: #e95f5f;
    width: 80%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

#select-algorithm-error p {
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: center;
    color: #FDFDFD;
}

.container-choose-algorithm main .choose-attributes header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    position: sticky;
    top: 0;
    background-color: #FDFDFD;
}

.container-choose-algorithm main .choose-attributes header .button-histogram {
    max-width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.container-choose-algorithm main .choose-attributes header .button-histogram p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    height: 100%;
    align-items: center;
}

.container-choose-algorithm main .choose-attributes header .buttons-all-clear {
    width: 100%;
    height: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.container-choose-algorithm main .choose-attributes header .buttons-all-clear button {
    width: auto;
    max-width: 45%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.container-choose-algorithm main .choose-attributes header .buttons-all-clear button p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    color: #22577A;
    display: flex;
    height: 100%;
    align-items: center;
}

.container-choose-algorithm main .choose-attributes .attribute {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    gap: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.container-choose-algorithm main .choose-attributes .attribute div {
    max-width: 20%;
    max-height: 100%;
    width: 12px;
    height: 12px;
    border: 1px solid #282828;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-choose-algorithm main .choose-attributes .attribute p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    display: flex;
    height: 100%;
    align-items: center;
}

@media (max-width: 1200px) {


    .container-choose-algorithm {
        width: calc(100vw * 0.83);
    }

}

@media (max-width: 1000px) {

    .popup-inner {
        padding: 15px;
    }

    .container-choose-algorithm {
        width: calc(100vw * 0.93);
    }

    .container-choose-algorithm main .choose-algorithm section .section-button p,
    .container-choose-algorithm main .choose-algorithm section #supervised button p,
    .container-choose-algorithm main .choose-algorithm section #unsupervised button p {
        font-size: 1.1em;
    }

    .container-choose-algorithm main .choose-algorithm section #supervised button,
    .container-choose-algorithm main .choose-algorithm section #unsupervised button {
        height: 28px;
    }

    .container-choose-algorithm main .container-info-execute article header p {
        font-size: 12px;
    }

    .container-choose-algorithm main .container-info-execute article {
        max-height: 50%;
    }

    .container-choose-algorithm main .container-info-execute article p {
        font-size: 1.1em;
        line-height: 15px;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute .input input,
    .container-choose-algorithm main .container-info-execute .choose-main-attribute select {
        height: 30px;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute a button p {
        font-size: 12px;
    }

    .container-choose-algorithm main .choose-attributes header .button-histogram p,
    .container-choose-algorithm main .choose-attributes header .buttons-all-clear button p,
    .container-choose-algorithm main .choose-attributes .attribute p {
        font-size: 10px;
    }

}

@media (max-width: 700px) {

    .container-choose-algorithm main .choose-algorithm section .section-button p,
    .container-choose-algorithm main .choose-algorithm section #supervised button p,
    .container-choose-algorithm main .choose-algorithm section #unsupervised button p {
        font-size: 1em;
    }

    .container-choose-algorithm main .container-info-execute article p {
        font-size: 1em;
        line-height: 12px;
    }

}

@media (max-width: 600px) {

    .container-choose-algorithm {
        width: calc(100vw * 0.7);
        height: calc(100vh * 0.8);
    }

    .container-choose-algorithm main {
        overflow-y: auto;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 5px;
    }

    .container-choose-algorithm main::-webkit-scrollbar {
        display: none;
    }

    .container-choose-algorithm main {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .container-choose-algorithm main .choose-algorithm,
    .container-choose-algorithm main .model-representation,
    .container-choose-algorithm main .container-info-execute,
    .container-choose-algorithm main .choose-attributes {
        width: 100%;
        height: auto;
    }

    .container-choose-algorithm main .model-representation {
        height: 350px;
    }

    .container-choose-algorithm main .container-info-execute article,
    .container-choose-algorithm main .container-info-execute .choose-main-attribute {
        height: auto;
    }

    .container-choose-algorithm main .container-info-execute article {
        max-height: none;
    }

    .container-choose-algorithm main .container-info-execute article p {
        height: auto;
        overflow: hidden;
        line-height: 20px;
    }

    .container-choose-algorithm main .container-info-execute article header {
        height: 30px;
    }

    .container-choose-algorithm main .choose-attributes {
        overflow: hidden;
    }

    .container-choose-algorithm main .choose-algorithm section .section-button p {
        font-size: 1.5em;
    }

    .container-choose-algorithm main .choose-algorithm section #supervised button,
    .container-choose-algorithm main .choose-algorithm section #unsupervised button {
        height: 34px;
    }

    .container-choose-algorithm main .choose-algorithm section #supervised button p,
    .container-choose-algorithm main .choose-algorithm section #unsupervised button p {
        font-size: 1.4em;
    }

    .container-choose-algorithm main .container-info-execute article header p {
        font-size: 17px;
    }

    .container-choose-algorithm main .container-info-execute article p {
        font-size: 1.5em;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute .dataset-name p {
        font-size: 18px;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute .input header p,
    .container-choose-algorithm main .container-info-execute .choose-main-attribute .input input {
        font-size: 14px;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute a button {
        width: 212px;
    }

    .container-choose-algorithm main .container-info-execute .choose-main-attribute a button p {
        font-size: 15px;
    }

    .container-choose-algorithm main .choose-attributes header .button-histogram p {
        font-size: 15px;
    }

    .container-choose-algorithm main .choose-attributes header .buttons-all-clear button p {
        font-size: 13px;
    }

    .container-choose-algorithm main .choose-attributes .attribute p {
        font-size: 14px;
    }

    .container-choose-algorithm main {
        gap: 20px;
    }

}

@media (max-width: 450px) {

    .popup-inner {
        padding: 20px;
    }

    .container-choose-algorithm {
        width: calc(100vw * 0.72);
    }

}

@media (max-width: 350px) {

    .container-choose-algorithm {
        width: calc(100vw * 0.8);
    }

}