 .model-algorithm .main-algorithm .model-result-3 {
    position: relative;
    height: 100%;
    width: 78.85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

 .model-algorithm .main-algorithm .model-result-3 .container-anomalies {
    height: 100%;
    width: 36.35%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 6px;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    padding: 12px;
    position: relative;
 }

 .model-algorithm .main-algorithm .model-result-3 .container-anomalies header {
    padding: 0;
    margin: 0;
    position: sticky;
    background-color: #E8EAED;
    width: 100%;
    left: 0;
    top: 0px;
    padding: 12px;
    border-radius: 6px;
 }

 .model-algorithm .main-algorithm .model-result-3 .container-anomalies .anomalies-result {
    width: 100%;
    height: auto;
    max-height: 96%;
    display: flex;
    gap: 6px;
    flex-direction: column;
 }

.model-algorithm .main-algorithm .model-result-3 .container-graphic {
    height: 100%;
    width: calc(100% - 36.35% - 20px);
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}

@media (max-width: 800px) {
   .model-algorithm .main-algorithm .model-result-3 .container-anomalies {
      padding: 3px;
   }
   
}

@media (max-width: 500px) {
    .model-algorithm .main-algorithm .model-result-3 {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
     }

     .model-algorithm .main-algorithm .model-result-3 .container-anomalies {
        width: 100%;
        max-height: 300px;
     }

     .model-algorithm .main-algorithm .model-result-3 .container-graphic {
        height: 450px;
        width: 100%;
    }
    
}
