.container-sidebar {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    color: #282828;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 1s;
    min-width: 212px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.container-sidebar .sidebar-menu {
    height: 98%;
    width: 100%;
    transition: 1s;
    display: flex;
    flex-direction: column;
}

.container-sidebar .sidebar-menu .sidebar-version {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    height: 25px;
}

.container-sidebar .sidebar-menu .sidebar-version span {
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
    font-weight: 700;
}

.container-sidebar .sidebar-menu .sidebar-profile {
    width: 100%;
    height: 86px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    transition: 0.5s;
    background: #ffffff;
    border: none;
}

.container-sidebar .sidebar-menu .sidebar-profile:hover {
    background-color: #e5e5e5;
}

.container-sidebar .sidebar-menu .sidebar-profile img {
    height: 62px;
    width: 62px;        
    overflow: hidden;
    object-fit: scale-down;
    border-radius: 50%;
}

.container-sidebar .sidebar-menu .sidebar-profile .sidebar-profile-name {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    width: 60%;
    text-align: left;
}

.container-sidebar .sidebar-menu .sidebar-profile .sidebar-profile-name span {
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-sidebar .sidebar-menu .sidebar-buttons {
    width: 100%;
    height: 87%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-sidebar .sidebar-menu .sidebar-buttons .sidebar-button {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #282828;
    cursor: pointer;
    padding: 0px 15px;
    border-bottom: 1px solid #e5e5e5;
    transition: 0.2s;
    border: none;
    background: #ffffff;
}

.container-sidebar .sidebar-menu .sidebar-buttons .sidebar-button:hover {
    background-color: #F2F2F2;
}

.container-sidebar .sidebar-menu .sidebar-buttons .sidebar-links-1 {
    width: 100%;
    height: 75%;
    overflow-y: auto;
}

.container-sidebar .sidebar-menu .sidebar-buttons .sidebar-links-2 {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.container-sidebar .sidebar-logo {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container-sidebar .sidebar-logo .sidebar-logo-1 {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-sidebar .sidebar-logo .sidebar-logo-1 img {
    width: 50%;
    height: 60%;
}

.container-sidebar .sidebar-logo .sidebar-logo-2 {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.container-sidebar .sidebar-logo .sidebar-logo-2 a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    height: 60%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-sidebar .sidebar-logo .sidebar-logo-2 a img {
    width: 100%;
    height: 100%;
}

.container-sidebar .sidebar-menu .sidebar-buttons .sidebar-button a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

@media (max-width: 1200px) {

    .container-sidebar {
        width: 212px;
    }

    .container-sidebar .sidebar-menu .sidebar-profile img {
        height: 54px;
        width: 54px;        
    }
}

@media (max-width: 940px) {

    .container-sidebar {
        min-width: 50px;
        width: 50px;
    }

    .container-sidebar .sidebar-menu .sidebar-version span,
    .container-sidebar .sidebar-render-projects,
    .container-sidebar .sidebar-menu .sidebar-profile .sidebar-profile-name,
    .container-sidebar .sidebar-menu .sidebar-buttons .sidebar-button span {
        display: none; 
    }

    .container-sidebar .sidebar-menu .sidebar-profile img {
        height: 40px;
        width: 40px;        
    }

    .container-sidebar .sidebar-menu .sidebar-profile svg {
        width: 42px;
        height: 42px;
    }

    .container-sidebar .sidebar-menu .sidebar-buttons .sidebar-button {
        justify-content: center;
        padding: 0;
    }

    .container-sidebar .sidebar-menu .sidebar-buttons .sidebar-links-1 {
        height: 65%;
    }

    .container-sidebar .sidebar-menu .sidebar-buttons .sidebar-links-2 {
        height: 35%;
    }

    .container-sidebar .sidebar-menu {
        height: 81.5%;
    }

    .container-sidebar .sidebar-logo {
        height: 15%;
    }

    .container-sidebar .sidebar-logo .sidebar-logo-1 {
        height: 33.33%;
        width: 80%;
    }

    .container-sidebar .sidebar-logo .sidebar-logo-1 img {
        width: 100%;
        height: 100%;
    }

    .container-sidebar .sidebar-logo .sidebar-logo-2 {
        height: 66.66%;
        flex-direction: column;
        width: 80%;
    }

    .container-sidebar .sidebar-logo .sidebar-logo-2 a {
        height: 50%;
        width: 100%;
    }
}


