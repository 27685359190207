.importancia-popup-linear {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
}

.importancia-popup-linear .content-popup{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-left: -70px;
}

.importancia-popup-linear  #texto-popup{
    display: flex;
    justify-content: flex-start;
    min-width: 500px ;
    height: 75px;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
}

.importancia-popup-linear .content-popup  .info-popup{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 500px;
    width: auto;
    min-height: min-content;
    height: 450px;
    flex-wrap: nowrap;
    position: relative;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    background-color: #F2F2F2;
    overflow: auto;
    margin-left: 70px;
}

.importancia-popup-linear .content-popup  .info-popup .names-popup{
 width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}