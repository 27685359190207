.cb-box input[type=checkbox]  {
    background: red;
}

.cb-box input[type=checkbox]:hover  {
    cursor: pointer;
}

.caixa {
    
}
