#download-model-popup {
    width: 260px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

#download-model-popup #title-msg {
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    position: absolute;
    top: 20px;
    margin-left: -40px;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 20px;
    width: 100%;
}

#download-model-popup #description {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    margin-top: 65px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

#download-model-popup #buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#download-model-popup .buttons .download {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

#download-model-popup .buttons .download #download-icon {
    padding-left: 5px;
    margin-right: 8px;
}