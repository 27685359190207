.containerMetrics p,
.containerMetrics nav,
.containerMetrics nav ul,
.containerMetrics button,
.containerMetrics main,
.containerMetrics select {
    padding: 0;
    margin: 0;
    font-size: 1em;
}

.containerMetrics {
    width: calc(100vw * 0.8455);
    height: calc(100vh * 0.7778);
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    color: #282828;
    font-family: Montserrat;
    font-style: normal;
    letter-spacing: 0.2px;
    font-size: 10px;
    background-color: #FFFFFF;
}

.containerMetrics .containerHeader {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.containerMetrics .containerHeader p {
    position: absolute;
    left: 0;
    font-size: 2.4em;
    line-height: 34px;
    font-weight: 300;
}

.containerMetrics .containerHeader nav {
    width: 624px;
    height: 100%;
}

.containerMetrics .containerHeader nav ul {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
}

.containerMetrics .containerHeader nav ul li {
    height: 100%;
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.containerMetrics .containerHeader nav ul li button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: inherit;
    border: none;
    background-color: transparent;
    font-size: 1.4em;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: 500;
    color: #1C1B1F;
    font-family: Montserrat;
}

.containerMetrics .containerHeader nav ul li button:hover {
    background-color: #205d5e1f;
}

.containerMetrics main {
    width: 100%;
    height: calc(100vh * 0.7778 - 48px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
}

.containerMetrics main section {
    width: 50%;
    height: 100%;
}

.containerMetrics main .resultScores {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
}

.containerMetrics main .resultScores div {
    padding: 0;
    margin: 0;
}

.containerMetrics main .resultScores .scores {
    width: 100%;
    height: 51.56%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
}

.containerMetrics main .resultScores .scores .score {
    width: 30%;
    height: 47%;
    border-radius: 8px;
    border: 1px solid #BDBDBD;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.containerMetrics main .resultScores .containerDropdown {
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.containerMetrics main .resultScores .containerDropdown select {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 15px;
    font-family: Montserrat;
}

.containerMetrics main .resultScores .containerDropdown p,
.containerMetrics main .resultScores .scores .score p {
    text-align: left;
    font-size: 1.7em;
    font-weight: 400;
    line-height: 15px;
}

.containerMetrics main .resultScores .scores .score span {
    font-size: 2.2em;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-top: 20px;
}

.containerMetrics main .resultScores .containerDropdown select {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
    height: 100%;
    width: 150px;
    border: 1px solid #BDBDBD;
    padding-left: 5px;
    font-family: Montserrat;
}

.containerMetrics main .confusionMatrix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.containerMetrics main .confusionMatrix header {
    width: 80%;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E0EC;
}

.containerMetrics main .confusionMatrix header p {
    font-size: 2em;
    line-height: 20px;
    font-weight: 500;
    align-items: center;
    color: #1C1B1F;
}

.containerMetrics main .containerComparation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 10px;
}

.containerMetrics main .containerComparation nav {
    width: 100%;
    height: 11.37%;
    display: flex;
}

.containerMetrics main .containerComparation nav ul {
    display: flex;
    height: 100%;
    max-width: 100%;
    align-items: center;
    flex-direction: row;
    padding: 0 24px;
}

.containerMetrics main .containerComparation nav ul li{
    height: 100%;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E7E0EC;
}

.containerMetrics main .containerComparation nav ul li button {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    transition: 0.2s;
    background-color: transparent;
    color: #1C1B1F;
    font-family: Montserrat;
}

.containerMetrics main .containerComparation nav ul li button:hover {
    padding-bottom: 15px;
}

.containerMetrics main .containerComparation .containerTable {
    width: 100%;
    height: 88.63%;
    display: flex;
    flex-direction: column;
}

.containerMetrics main .containerComparation .containerTable header {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
}

.containerMetrics main .containerComparation .containerTable header select {
    max-height: 80%;
    max-width: 80%;
    width: 350px;
    margin-left: 20px;
    cursor: pointer;
    background-color: transparent;
    gap: 10px;
    padding: 12px 24px;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    max-height: 42px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #282828;
    font-family: Montserrat;
}

.containerMetrics main .containerComparation .containerRocCurve {
    width: 100%;
    height: 88.63%;
    display: flex;
    flex-direction: row;
}

.containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
}

.containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect {
    width: 80%;
    height: 42px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 15px;
    gap: 8px;
}

.containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span {
    max-width: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
}

.containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
}

.containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect select {
    height: 100%;
    width: 50%;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 15px;
    padding: 5px;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels {
    margin: 0px auto;
    width: 80%;
    max-height: 85%;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 12px;
    font-size: 15px;
    color: #000000;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels header {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: 500;
    border-radius: 10px 10px 0 0;
    border: 1px solid #E0E0E0;
    background-color: #F4F4F4;
    padding: 12px 24px;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel {
    background-color: transparent;
    padding: 12px 24px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model #text {
    height: auto;
    max-width: 85%;
    display: flex;
    align-items: center;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model #text p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model #circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel #checkbox {
    width: 23px;
    height: 23px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #505050;
    transition: 0.4s;
    padding-left: 1px;
}

.containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel #checkbox svg {
    color: #FFFFFF;
    width: 16px;
    height: 16px;
}

.containerMetrics main .containerComparation .containerRocCurve .containerGraphic {
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1200px) {

    .containerMetrics main .resultScores .scores .score {
        padding: 10px;
        gap: 10px;
    }

    .containerMetrics main .resultScores .containerDropdown p,
    .containerMetrics main .resultScores .scores .score p {
        font-size: 1.3em;
        overflow-x: hidden;
    }

    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel {
        padding: 6px 12px;
    }

}

@media (max-width: 1000px) {

    .containerMetrics .containerHeader nav {
        width: 500px;
    }

    .containerMetrics .containerHeader nav ul li button {
        font-size: 1.2em;
    }

    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect {
        width: 80%;
        height: 42px;
        margin: 0px auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 15px;
        gap: 8px;
    }
    
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span {
        font-size: 11px;
    }
    
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span p {
        font-size: 11px;
    }
    
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect select {
        font-size: 11px;
    }
    
    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels {
        font-size: 11px;
    }

    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model {
        width: 75%;
    }

    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model #text {
        max-width: 70%;
    }
}

@media (max-width: 900px) {

    .containerMetrics .containerHeader nav {
        width: 390px;
    }

}


@media (max-width: 800px) {

    .containerMetrics main .resultScores .containerDropdown p,
    .containerMetrics main .resultScores .scores .score p {
        font-size: 1.1em;
    }

    .containerMetrics main .containerComparation .containerRocCurve {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span,
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span p,
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect select,
    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels {
        font-size: 15px;
    }


    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve {
        width: 100%;
        max-height: 400px;
    }

    .containerMetrics main .containerComparation .containerRocCurve .containerGraphic {
        width: 100%;
    }

    .containerMetrics main .containerComparation .containerRocCurve .containerGraphic svg {
        padding: 10px;
    }

    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels .containerModel .name-model #circle {
        width: 12px;
        height: 12px;
    }
}

@media (max-width: 700px) {


    .containerMetrics .containerHeader nav {
        width: 300px;
    }

    .containerMetrics main .containerComparation .containerRocCurve .containerGraphic svg {
        padding: 0px;
        margin: 0;
    }

}

@media (max-width: 600px)  {

    .containerMetrics .containerHeader p {
        font-size: 1.7em;
    }

    .containerMetrics .containerHeader {
        width: 100%;
    }
    
    .containerMetrics .containerHeader nav {
        width: 220px;
        height: 100%;
        position: absolute;
        right: 0;
        font-size: 0.90em;
    }

    .containerMetrics main {
        flex-direction: row;
        overflow: hidden auto;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .containerMetrics main .resultScores {
        height: auto;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
    }

    .containerMetrics main .resultScores .scores {
        height: auto;
        max-height: 100%;
    }

    .containerMetrics main .resultScores .scores .score {
        height: 80px;
        gap: 0px;
        padding: 10px;
        border-radius: 10px;
    }   

    .containerMetrics main .resultScores .containerDropdown p,
    .containerMetrics main .resultScores .scores .score p {
        font-size: 1.2em;
    }

    .containerMetrics main .resultScores .scores .score p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }

    .containerMetrics main .resultScores .scores .score span {
        font-size: 1.8em;
        display: flex;
        justify-content: center;
    }

    .containerMetrics .containerDropdown {
        justify-content: space-around;
    }

    .containerMetrics .containerDropdown p {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .containerMetrics .confusionMatrix {
        width: 100%;
        height: auto;
    }

    .containerMetrics .confusionMatrix svg {
        width: 100%;
        height: auto;
    }
    
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span,
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect span p,
    .containerMetrics main .containerComparation .containerRocCurve .containerInfoRocCurve .containerSelect select,
    .containerMetrics .containerComparation .containerRocCurve .containerInfoRocCurve .containerModels {
        font-size: 13px;
    }

}