.table-content {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: nowrap;
}

.table-content table {
    padding: 10px;
    margin: 0;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-content table thead,
.table-content table tbody {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.table-content table thead #user {
    border-bottom: 1px solid #CECECE;
}

.table-content table thead tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}

.table-content table thead tr th {
    width: 50%;
    height: 100%;
}

.table-content table tbody tr {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.table-content table tbody tr th {
    width: 50%;
    height: 100%;
}