.model-result-1 #container {
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   z-index: 0;
}

.model-result-1 svg {
   z-index: 0;
}

.model .change-visualization {
   width: auto;
   height: auto;
   margin: 2px;

   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-end;
   z-index: 1;
}

.model .change-visualization button {
   cursor: pointer;
   width: 120px;
   height: 34px;
   gap: 4px;
   border: 1px solid #79747E;
   background-color: #FDFDFD;
   display: flex;
   align-items: center;
   justify-content: center;
}

.model .change-visualization button p {
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   display: flex;
   align-items: center;
   height: 100%;
}

.model .change-visualization #left {
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
}

.model .change-visualization #right {
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
}

.model .change-visualization .active {
   background-color: #22577A;
   border: none;
}

.model .change-visualization .active p {
   font-weight: 500;
   color: #FDFDFD
}

.model-info .container-editable {
   position: sticky;
   top: 0px;
   background-color: #FDFDFD;
}

.model-info .info-slider {
   width: 100%;
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   height: auto;
}

.model-info .info-slider p {
   padding: 0;
   margin: 0;
   overflow-x: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 14px;
   font-weight: 400;
   line-height: 15px;
   color: #282828;
   align-items: center;
   display: flex;
   height: 18px;
   max-width: 100%;
}

.model-info .dropdown-container {
   display: flex;
   flex-direction: column;
   margin: 0px;
   padding: 0px;
   height: auto;
   width: 100%;
   text-align: left;
}

.model-info .collapsible-categorical {
   width: 100%;
   height: auto;
   gap: 5px;
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
}

.model-info .collapsible-categorical .info-field {
   width: 100%;
   height: auto;
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
}

.model-info .collapsible-categorical .info-field button {
   width: 100%;
   height: 32px;
   display: flex;
   flex-direction: row;
   padding: 0;
   margin: 0;
   cursor: pointer;
   align-items: center;
   justify-content: space-between;
   padding: 0px 6px;
   background: #38A3A5;
   border-radius: 8px;
   border: none;
   z-index: 1;
}

.model-info .collapsible-categorical .info-field button p {
   max-width: 80%;
   height: 100%;
   display: flex;
   align-items: center;
   width: auto;
   overflow-x: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   padding: 0;
   margin: 0;
   color: #fdfdfd
}

.model-info .collapsible-categorical .info-field .column {
   width: 100%;
   max-height: 200px;
   height: auto;
   display: flex;
   flex-direction: column;
   overflow-y: auto;
   padding: 0;
   margin: 0;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
   border: 1px solid #cecece;
   padding: 5px;
   margin-top: -16px;
   padding-top: 19px;
   gap: 3px;
}

.model-info .collapsible-categorical .info-field .column p {
   padding: 0px;
   margin: 0px;
   display: flex;
   align-items: center;
   padding: 0px 4px;
}

.model-info .dropdown-container .dropdown select {
   cursor: pointer;
}

#number-of-centroids-heading {
   width: 100%;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   color: #282828;
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 100%;
}