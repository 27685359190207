.container-history p,
.container-history span,
.container-history div {
    padding: 0;
    margin: 0;
    color: #282828;
}

.container-history button {
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.container-history {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 10px;
    font-family: Montserrat;
    letter-spacing: 0.2px;
    line-height: 15px;
    font-style: normal;
    background: url("../../../images/backgroubd_myprojects_1376.svg");
    background-position: center center;
    background-size: cover;
    padding: 20px;
}

.container-history header {
    width: 100%;
    max-height: 15%;
    padding-bottom: 20px;
}

.container-history header article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.container-history header article p {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 15px;
    text-align: left;
}

.container-history header article #title {
    font-weight: 300;
    font-size: 3.2em;
    line-height: 34px;
}

.container-history .delete-tasks {
    background-color: #22577a;
    border: none;
    padding: 4px 20px;
    border-radius: 10px;
    gap: 10px;
    width: auto;
    height: auto;
    max-width: 100%;
}

.container-history .delete-tasks p {
    color: #FDFDFD;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    max-width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.5px;
}

.container-history .delete-tasks-disabled {
    cursor: not-allowed;
    background-color: #BDBDBD;
}

.container-history main {
    width: 100%;
    height: 85%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    padding-bottom: 2px;
}

.container-history main section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    border-radius: 10px;
    border-bottom: 1px solid #bebebe;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.container-history main section button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
}

.container-history main section button span {
    font-size: 2.4em;
    font-weight: 300;
}

.container-history main section button span svg {
    width: 25px;
    height: 25px;
}

.container-history main section #models,
.container-history main section #datasets {
    width: 100%;
    max-height: 500px;
}

.container-history main section .history-models,
.container-history main section .history-datasets {
    display: flex;
    width: 100%;
}

.container-history main section .history-models .no-task,
.container-history main section .history-datasets .no-task,
.container-history main section .history-models-mobile .no-task,
.container-history main section .history-datasets-mobile .no-task {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 10px;
}

.container-history main section .no-task p {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2.4em;
    color: lightgrey;
    text-align: left;
} 

.container-history main section .history-models-mobile,
.container-history main section .history-datasets-mobile {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden auto;
    gap: 15px;
    width: 100%;
    max-height: 450px;
    padding-bottom: 10px;
}

.container-history main section .history-models table,
.container-history main section .history-datasets table  {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.container-history main section .history-models table thead,
.container-history main section .history-datasets table thead {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-history main section .history-models table thead tr,
.container-history main section .history-datasets table thead tr {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.container-history main section .history-models table tbody,
.container-history main section .history-datasets table tbody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    overflow: hidden auto;
}

.container-history main section .history-models table tbody tr,
.container-history main section .history-datasets table tbody tr {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #bebebe;
}

.container-history main section .history-models table th,
.container-history main section .history-datasets table th  {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-history main section .history-models table th p,
.container-history main section .history-datasets table th p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-history main section .history-models table #name,
.container-history main section .history-models table #project,
.container-history main section .history-models table #dataset,
.container-history main section .history-models table #date,
.container-history main section .history-models table #button {
    width: 14.6%;
}

.container-history main section .history-models table #algorithm {
    width: 7%;
}

.container-history main section .history-models table #size {
    width: 5%;
}

.container-history main section .history-models table #trash {
    width: 3%;
    height: 100%;
}

.container-history main section .history-models table #trash button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.container-history main section .history-models table #status div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 2px;
}

.container-history main section .history-models table #status,
.container-history main section .history-models table #restart {
    width: 6%;
}

.container-history main section .history-models table #restart button,
.container-history main section .history-models table #button button,
.container-history main section .history-datasets table #button button  {
    width: 80%;
    height: 80%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.container-history main section .history-models table #button button p,
.container-history main section .history-datasets table #button button p {
    margin: 0;
    padding: 0;
    color: #FDFDFD;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 16px;
}

.container-history main section .history-datasets table #icon {
    width: 5%;
}

.container-history main section .history-datasets table #name {
    width: 20%;
}

.container-history main section .history-datasets table #date {
    width: 15%;
}

.container-history main section .history-datasets table #size {
    width: 15%;
}

.container-history main section .history-datasets table #status {
    width: 8%;
}

.container-history main section .history-datasets table #button {
    width: 15%;
}

.container-history main section .history-datasets table #button button {
    width: 90%;
}

.container-history main section .history-datasets table #trash {
    width: 3%;
}

.container-history main section .history-datasets table #trash button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-history main section .restart {
    background-color: #22577a;
}

.container-history main section .cancel {
    background-color: #EB5757;
}

.container-history main section .open {
    background-color: #38A3A5;
}

.container-history main section .undefined {
    background-color: #ececec;
    cursor: not-allowed;
}

@media (max-height: 800px){
    .container-history main section .history-models,
    .container-history main section .history-datasets {
        max-height: 400px;
    }
}

@media (max-height: 700px){
    .container-history main section .history-models,
    .container-history main section .history-datasets {
        max-height: 300px;
    }
}

@media (max-width: 1400px){

    .container-history main section .history-models table th,
    .container-history main section .history-datasets table th {
        font-size: 9px;
    }

}

@media (max-width: 1300px){
    .container-history main section .history-models table th,
    .container-history main section .history-datasets table th  {
        font-size: 8px;
    }
}

@media (max-width: 940px){

    .container-history main section .history-datasets table #date,
    .container-history main section .history-datasets table #name,
    .container-history main section .history-datasets table #button {
        width: 20%;
    }

    .container-history main section .history-models table th,
    .container-history main section .history-datasets table th  {
        font-size: 7.5px;
    }
}

@media (min-width: 500px){
    .container-history main section .history-models,
    .container-history main section .history-datasets {
        display: flex;  
    }

    .container-history main section .history-models-mobile,
    .container-history main section .history-datasets-mobile {
        display: none;
    }
}

@media (max-width: 500px){
    .container-history main section button {
        justify-content: space-between;
    }

    .container-history main section .history-models,
    .container-history main section .history-datasets {
        display: none;
    }

    .container-history main section .history-models-mobile,
    .container-history main section .history-datasets-mobile {
        display: flex;
    }

    .container-history main section .no-task p {
        font-size: 1.7em;
    }
}