.navButtons {
    display: flex;
    margin-bottom: 1.5rem;
}

.navButtons button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0.7em 4em;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #E7E0EC;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 600;
}

.navButtons .activeButton {
    border-bottom: 2px solid #38A3A5;
}

@media(max-width:590px) {
    .navButtons button {
        padding: 0.7em 1.2em;
    }

}