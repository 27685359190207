.BarChart {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: fit-content;
    height: 31px;
}

#new-barchart {
    margin-left: 5px;
    width: 156px;
    height: 31px;
}
