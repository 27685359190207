.containerDatabaseDataset {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px; 
}

.containerDatabaseDataset .containerButton{
    margin: 0px;
    padding: 0px;
    width: 21.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 43px;
    gap: 20px;
    justify-content: flex-start;
}

.containerDatabaseDataset .containerButton button {
    text-transform: none;
    margin: 0px;
    padding: 0px;
    width: 150px;
    height: 34px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #282828;
    display: flex;
    align-items: center;
    gap: 4%;
    padding: 0 20px 0 20px;
    background-color: transparent;
    color: #282828;
}

.containerDatabaseDataset .containerButton .buttonSelected {
    background-color: #22577A;
    color: #FDFDFD;
    border: none;
}

.containerDatabaseDataset .containerButton button:hover {
    background-color: #2f729e;
}

.containerDatabaseDataset .containerInputMySQL {
    height: 100%;
    width: 78.67%;
    display: flex;
    flex-direction: column;
}

.containerDatabaseDataset .containerInputMySQL header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.containerDatabaseDataset .containerInputMySQL header p {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
}

.containerDatabaseDataset .containerInputMySQL header .textInactive {
    color: #ddd;
    font-family: 400;
}

.containerDatabaseDataset .containerInputMySQL header .buttonSwitch {
    width: 70px;
    height: 50%;
    background-color: #38A3A5;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-radius: 10px;
    transition: 0.5s ease;
}

.containerDatabaseDataset .containerInputMySQL header .buttonSwitch #ball {
    background-color: #FFFFFF;
    height: 65%;
    width: 25px;
    border-radius: 8px;
}

.containerDatabaseDataset .containerInputMySQL header .buttonActive {
    padding-left: 40px;
}

.containerDatabaseDataset .containerInputMySQL .inputsMySQL {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 40px 52px;
    flex-wrap: wrap;
    overflow: hidden auto;
    gap: 20px;
}

.containerDatabaseDataset .containerInput {
    height: 100%;
    width: 78.67%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 39px 0px 40px 52px;
    gap: 20px;
    flex-wrap: wrap;
}

.containerDatabaseDataset .containerInput div,
.containerDatabaseDataset .containerInputMySQL .inputsMySQL div{
    margin: 0px;
    padding: 0px;
    width: 28%;
    height: 55px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
}

.containerDatabaseDataset .containerInput div label,
.containerDatabaseDataset .containerInputMySQL .inputsMySQL  div label{
    width: 100%;
    height: 45.46%;
    display: flex;
    margin-top: auto;
    white-space: nowrap;
}

.containerDatabaseDataset .containerInput div input,
.containerDatabaseDataset .containerInputMySQL .inputsMySQL div input{
    width: 100%;
    height: 54.54%;
    border: 1px solid #282828;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
}

@media (max-width: 1440px) {

    .containerDatabaseDataset .containerButton {
        width: 30%;
    }

    .containerDatabaseDataset .containerInputMySQL,
    .containerDatabaseDataset .containerInput {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .containerDatabaseDataset {
        flex-direction: column;
    }

    .containerDatabaseDataset .containerButton {
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 22%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .containerDatabaseDataset .containerInputMySQL,
    .containerDatabaseDataset .containerInput {
        height: 78%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .containerDatabaseDataset .containerInputMySQL .inputsMySQL {
        flex-wrap: nowrap;
        width: 80%;
        padding: 0;
    }

    .containerDatabaseDataset .containerInput {
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }


    .containerDatabaseDataset .containerInputMySQL .inputsMySQL div,
    .containerDatabaseDataset .containerInput div {
        width: 100%;
    }

    .containerDatabaseDataset .containerInputMySQL .inputsMySQL div input,
    .containerDatabaseDataset .containerInput div input {
        width: 90%;
        height: 40px;
    }
}