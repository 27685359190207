.containerNewDatasetPopup {
    width: 50vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #282828;
    background-color: #FDFDFD;
    margin: 0px;
    padding: 0px;
}

.containerNewDatasetPopup .containerHeaderNewDataset {
    width: 100%;
    height: 14%;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    display: flex;
    align-items: center;
    padding: 0px 0px 15px 20px;
}

.containerNewDatasetPopup .containerContent {
    width: 100%;
    height: 86%;
}

.containerNewDatasetPopup .containerContent .containerHeaderContent {
    width: 100%;
    height: 8.71%;
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05); 
}

.containerNewDatasetPopup .containerContent .containerHeaderContent button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-family: inherit;
    height: 100%;
}

.containerNewDatasetPopup .containerContent .containerHeaderContent .selected {
    height: 100%;
    color: var(--cadet-blue, #38A3A5);
    border-bottom: 2px solid var(--cadet-blue, #38A3A5);
}

.containerNewDatasetPopup .containerContent .containerUpload {
    width: 100%;
    height: 68.68%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerNewDatasetPopup .containerContent .containerLinkProject{
    width: 100%;
    height: 21.32%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml .containerInputFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml .containerInputFile label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #22577A;
    padding: 8px 20px;
    text-transform: uppercase;
    border-radius: 8px;
    border: none;
    color: #FDFDFD;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
}

.containerNewDatasetPopup .containerContent .containerLinkProject p {
    height: 28%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml .containerNameFile {
    width: 30%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml .containerNameFile p {
    font-size: 14px;
    font-family: "Montserrat";
    gap: 5px;
    background-color: transparent;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml .containerNameFile p span {
    background-color: #eee;
    color: #282828;
    font-weight: 600;
    padding: 5px;
    font-size: 16px;
}

.containerNewDatasetPopup .containerContent .containerSelect {
    position: relative;
    width: 25%;
    height: 25%;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid #BDBDBD;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
}

.containerNewDatasetPopup .containerContent .containerSelect p {
    height: 40px;
    width: 100%;
    text-align: left;
    color: var(--cadet-blue, #38A3A5);
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.containerNewDatasetPopup .containerContent .containerSelect .containerOptions {
    position: absolute;
    bottom: 30px;
    width: 100%;
    background-color: #FDFDFD;
    border: 1px solid #BDBDBD;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    padding: 2px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 10;
}

.containerNewDatasetPopup .containerContent .containerSelect .containerOptions div {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    margin: 0px;
    background-color: transparent;
    justify-content: space-between;
    min-height: 40px;
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.containerNewDatasetPopup .containerContent .containerSelect .containerOptions .selected {
    background-color: rgb(228, 228, 228);
}

.containerNewDatasetPopup .containerContent .containerLinkProject button {
    height: 25%;
    width: 11.28%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    gap: 10px;
    padding: 8px 20px;
    text-transform: uppercase;
    border-radius: 8px;
    border: none;
    color: #FDFDFD;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
}

.containerNewDatasetPopup .containerContent .containerLinkProject .containerImportDatasetWiseml button {
    width: 15%;
    height: 25%;
}

@media (max-width: 1200px){
    .containerNewDatasetPopup {
        width: 70vw;
    }
}

@media (max-width: 900px){
    .containerNewDatasetPopup {
        width: 80vw;
        height: 75vh;
    }

    .containerNewDatasetPopup .containerContent .containerHeaderContent {
        gap: 20px;
    }

    .containerNewDatasetPopup .containerContent .containerHeaderContent button {
        font-size: 14px;
    }

    .containerNewDatasetPopup .containerContent .containerSelect {
        width: 50%;
        height: 30%;
    }

    .containerNewDatasetPopup .containerContent .containerLinkProject button {
        width: 35%;
        height: 30%;
    }
}

@media (max-width: 500px){

    .containerNewDatasetPopup {
        width: 80vw;
        height: 75vh;
    }

    .containerNewDatasetPopup .containerContent .containerHeaderContent {
        justify-content: space-between;
        gap: 0px;
    }

    .containerNewDatasetPopup .containerContent .containerHeaderContent button {
        font-size: 13px;
    }

    .containerNewDatasetPopup .containerContent .containerLinkProject {
        padding: 0 20px;
        justify-content: space-between;
    }

    .containerNewDatasetPopup .containerContent .containerLinkProject #info {
        display: none;
    }

    .containerNewDatasetPopup .containerContent .containerSelect {
        width: 55%;
        height: 35%;
    }

    .containerNewDatasetPopup .containerContent .containerLinkProject button {
        width: 35%;
        height: 35%;
    }

}

@media (max-width: 400px){

    .containerNewDatasetPopup .containerContent .containerLinkProject {
        padding: 0px;
    }

    .containerNewDatasetPopup .containerContent .containerSelect {
        width: 60%;
        height: 30%;
    }

    .containerNewDatasetPopup .containerContent .containerLinkProject button {
        width: 35%;
        height: 30%;
    }

}
