.importancia-popup-tree {
    /* min-width: 1000px; */
    height: 600px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.importancia-popup-tree .content-popup{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.importancia-popup-tree  #texto-popup{
    display: flex;
    justify-content: flex-start;
    min-width: 500px ;
    height: 75px;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
}

.importancia-popup-tree .content-popup .pie-chart{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 500px;
    height: 500px;
    /* margin-top: 75px; */
}

.importancia-popup-tree .content-popup  .info-popup{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /*justify-content:space-around;*/
    min-width: 400px;
    width: auto;
    min-height: min-content;
    height: 450px;
    flex-wrap: nowrap;
    position: relative;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    background-color: #F2F2F2;
    margin-left: 70px;
    margin-top: 25px;
    overflow: auto;
}

.importancia-popup-tree .content-popup  .info-popup .names-popup{
 width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}