.hidderLayers {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    justify-content: center;
    position: relative;
    height: auto;
    gap: 10px;
}

.hidderLayers p {
    padding: 0;
    margin: 0;
    /* overflow-x: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: #282828;
    height: 100%;
    align-items: center;
    display: flex;
}

.hidderLayers .control {
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 6px; 
    height: 24px;
}

.hidderLayers .control div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select:none;  
    -moz-user-select:none;     
    -ms-user-select:none;      
    user-select:none;
}

.hidderLayers .control div p {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
}

.hidderLayers .control button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select:none;  
    -moz-user-select:none;     
    -ms-user-select:none;      
    user-select:none;
    border: none;
    background-color: transparent;
}

.hidderLayers .control button p {
    font-size: 26px;
    font-weight: 500;
    line-height: 15px;
    height: 100%;
    display: flex;
    align-items: center;
}
 
.editNeurons {
    position: fixed;
    width: 239px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-height: 214px;
}

.editNeurons #editNeurounsRectangle {
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
    position: fixed;
    background: linear-gradient(45deg, #FFF 0%, #FFF 50%, transparent 51%, transparent 100%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    border-left: 1px solid rgba(0, 0, 0, 0.09);
    z-index: -1;
}

.editNeurons .headerEditNeurouns {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px; 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editNeurons .neurons {
    width: 100%;
    height: 125px;
    overflow-y: auto;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.editNeurons .neurons .containerLayer {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 8px;
}

.editNeurons .neurons .containerLayer .headerContainerLayers {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}


@media (max-width: 1350px){

    .hidderLayers p {
        font-size: 11px;
    }

    .hidderLayers .control div p {
        font-size: 14px;
    }

}

@media (max-width: 500px){

    .hidderLayers {
        gap: 15px;
    }

    .hidderLayers p {
        font-size: 14px;
        height: 20px;
    }

    .hidderLayers .control div,
    .hidderLayers .control button {
        width: 40px;
        height: 40px;
    }

    .hidderLayers .control div p {
        font-size: 15px;
    }

    .editNeurons #editNeurounsRectangle {
        transform: rotate(135deg);
    }
}