.model,
.model .model-algorithm {
    padding: 10px;
    width: 100%;
}

.model {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    flex-direction: column;
    font-family: Montserrat;
    font-style: normal;
    overflow: hidden;
}

.model .scroll {
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.model .headerModel {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 42px;
    padding: 5px 0px;
    justify-content: space-between;
}

.model #title {
    margin-left: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    width: auto;
}

.model #title .editicon {
    position: relative;
    top: 5px;
    margin-left: 15px;
}

.model #title .model-owner .owner-email {
    font-weight: 400;
}

.model .headerModel #top-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-right: 20px;
    height: 100%;
}

.model .headerModel #top-buttons button,
.model .headerModel #top-buttons .export-buttons .export-model button {
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: transparent;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #22577A;
    background-color: transparent;
    color: #22577A;
    cursor: pointer;
    padding: 0px 20px;
    height: 100%;
}


.model .headerModel #top-buttons button svg {
    width: 20px;
    height: 20px;
}

.model .headerModel #top-buttons button p,
.model .headerModel #top-buttons .export-buttons .export-model button p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.model .headerModel #top-buttons button {
    max-height: 100%;
}

.model .headerModel #top-buttons .disabled {
    border: none;
    cursor: not-allowed;
}

.model .headerModel #top-buttons .disabled svg path {
    fill: #e0e0e0;
}

.model .headerModel #top-buttons .disabled p {
    color: #e0e0e0;
}

.model .headerModel #top-buttons .enabled svg path {
    fill: #22577A;
}

.model .headerModel #top-buttons .fill {
    background-color: #22577A;
    color: #FDFDFD;
}

.model .headerModel #top-buttons .disabled-fill {
    background-color: #BDBDBD;
    cursor: not-allowed;
    border: none;
}

.model .headerModel #top-buttons .disabled-fill p {
    color: #FDFDFD;
}

.model .headerModel #top-buttons .export-buttons {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    position: relative;
}

.model .headerModel #top-buttons .export-buttons .export-model {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 35px;
    left: 2px;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: #FDFDFD;
    padding: 2px;
    gap: 2px;
    align-items: center;
    justify-content: center;
}

.model .headerModel #top-buttons .export-buttons .export-model button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0;
}

.model .headerModel #top-buttons #button-mobile {
    display: none;
}

/* .model .export-buttons .export-model button {
    width: inherit;
    height: 50%;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: none;
    background-color: transparent;
} */

/* .model .export-model button:hover {
    background-color: #F2F2F2;
} */

/* ==================== algoritmos ==================== */


.model .model-algorithm {
    height: calc(100% - 42px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.model .model-algorithm .main-algorithm {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 88.91%;
    gap: 20px;
    justify-content: space-between;
}

.model .model-algorithm .main-algorithm .left {
    height: 100%;
    width: 21.15%;
    display: flex;
    flex-direction: column;
}

.model .model-algorithm .main-algorithm .left .header-algorithm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.model .model-algorithm .main-algorithm .left .header-algorithm .name {
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.model .model-algorithm .main-algorithm .left .header-algorithm .name p {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 70%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.model .model-algorithm .main-algorithm .left .header-algorithm .name button {
    max-width: 70%;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.model .model-algorithm .main-algorithm .left .header-algorithm .name button p {
    max-width: 100%;
}

.model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #22577A;
}

.model .model-algorithm .main-algorithm .left .model-hyperparameters {
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    gap: 10px;
    max-height: 500px;
    padding: 10px 0px;
}

.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-slider {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
}

.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-slider p,
.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-checkbox p,
.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-dropdown p {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: #282828;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 2px 0px;
}

.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-checkbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 30px;
}

.model .model-algorithm .main-algorithm .left .model-hyperparameters .model-dropdown {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.model .model-algorithm .main-algorithm .left .model-execute {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.model .model-algorithm .main-algorithm .left .model-execute button {
    height: 32px;
    cursor: pointer;
    padding: 10px 0px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background-color: #22577A;
    color: #FDFDFD;
    gap: 10px;
    border: none;
    width: 100%;
}

.model .model-algorithm .main-algorithm .left .model-execute .btn--disabled--solid {
    background-color: var(--disabled);
    color: var(--disabled--text);
    border: 1px solid var(--disabled);
    cursor: not-allowed;
}

.model .model-algorithm .main-algorithm .model-result-1 {
    height: 100%;
    width: 58%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    resize: horizontal;
    overflow: auto;
    padding-right: 15px;
}

.model .model-algorithm .main-algorithm .model-result-1 svg,
.model .model-algorithm .main-algorithm .model-result-2 svg {
    width: 100%;
    height: 100%;
}

.model .model-algorithm .main-algorithm .model-result-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 78.85%;
    align-items: start;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}

.model .model-algorithm .main-algorithm .model-result-1 #save-status,
.model .model-algorithm .main-algorithm .model-result-2 #save-status {
    top: 5px;
    left: 5px;
}


.model .model-algorithm .main-algorithm .model-info {
    height: 100%;
    min-width: 20%;
    width: 20%;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 5px;
    overflow: auto;
    flex-wrap: nowrap;
    flex-grow: 1;

}

.model .model-algorithm .main-algorithm .model-info #info {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    min-height: 32px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.model .model-algorithm .main-algorithm .model-info #nameField {
    text-align: left;
    min-width: 55%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    color: #333;
    font-size: 1rem;
}

.model .model-algorithm .main-algorithm .model-info #operation {
    min-width: 15%;
    text-align: center;
    font-size: 1rem;
    color: #777;
    text-overflow: inherit;
    font-weight: bold;
    overflow-x: hidden;
}

.model .model-algorithm .main-algorithm .model-info #value {
    text-overflow: ellipsis;
    overflow-x: hidden;
    justify-content: end;
    min-width: 30%;
    text-align: right;
    color: #555;
    font-size: 0.95rem;
    padding-right: 0.4rem;
}
.model .model-algorithm .main-algorithm .model-info #value #info-array{
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
}

.model .model-algorithm .buttons-bottom-algorithm {
    width: 100%;
    height: calc(100% - 88.91%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.model-algorithm .buttons-bottom-algorithm select {
    padding: 8px 20px;
    width: auto;
    display: flex;
    align-items: center;
    max-height: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px;
    background-color: transparent;
    color: #22577A;
    cursor: pointer;
    border: 1px solid #22577A;
    outline: none;
}

.model .model-algorithm .buttons-bottom-algorithm button {
    padding: 8px 20px;
    border-radius: 8px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    background-color: transparent;
    color: #22577A;
    cursor: pointer;
}

.model .model-algorithm .buttons-bottom-algorithm #fill {
    background-color: #22577A;
    color: #FDFDFD;
}

.model .model-algorithm .buttons-bottom-algorithm #fill svg {
    color: #FDFDFD;
}

@media (max-width: 1500px) {

    .model .headerModel #title p {
        font-size: 18px;
        line-height: 30px;
    }

    .model .headerModel #top-buttons button {
        padding: 5px;
        height: 30px;
    }

    .model .headerModel #top-buttons p {
        font-size: 13px;
    }

}

@media (max-width: 1350px) {

    .model .model-algorithm .main-algorithm {
        gap: 10px;
    }

    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-slider p,
    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-checkbox p,
    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-dropdown p {
        font-size: 11px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name,
    .model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
        font-size: 11px;
    }

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        font-size: 13px;
    }
}

@media (max-width: 1200px) {

    .model .headerModel #title p {
        font-size: 15px;
    }

    .model .headerModel #top-buttons p {
        font-size: 12px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm .name {
        height: 20px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name,
    .model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
        font-size: 10px;
    }

    .model .model-algorithm .main-algorithm .left .model-hyperparameters,
    .model .model-algorithm .main-algorithm .left .model-execute {
        margin-top: 10px;
    }

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        font-size: 12.5px;
    }
}

@media (max-width: 1000px) {

    .model .headerModel #title p {
        font-size: 17px;
    }

    .model .headerModel #top-buttons button {
        padding: 8px;
        height: 28px;
    }

    .model .headerModel #top-buttons #button-model-trash {
        display: none;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm {
        gap: 8px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm svg {
        width: 20px;
        height: 20px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name,
    .model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
        font-size: 10px;
    }

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        font-size: 12px;
    }

}

@media (max-width: 900px) {

    .model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name,
    .model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
        font-size: 9px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm .name svg {
        height: 18px;
        width: 18px;
    }
}

@media (max-width: 800px) {

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        font-size: 10px;
        padding: 8px 15px;
    }
}

@media (max-height: 800px) {

    .model .headerModel #top-buttons button {
        padding: 4px 20px;
    }

    .model .headerModel #top-buttons button svg {
        width: 17px;
        height: 17px;
    }

    .model .headerModel #top-buttons button p {
        font-size: 13px;
        line-height: 14px;
    }

    .model .headerModel {
        height: 37px;
    }

    .model .model-algorithm {
        height: calc(100% - 37px);
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

}

@media (max-width: 700px) {

    .model .headerModel #title p {
        font-size: 17px;
    }

    .model .headerModel #top-buttons button {
        padding: 6px;
    }

    .model .headerModel #top-buttons #button-model-download,
    .model .headerModel #top-buttons #button-model-save {
        display: none;
    }

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        font-size: 8px;
        padding: 6px 15px;
    }
}

@media (max-width: 600px) {

    .model .headerModel #title p {
        font-size: 15px;
    }

    .model .headerModel #top-buttons button {
        padding: 5px;
    }

}

@media (max-width: 700px) {
    .model {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        margin: 0;
        width: calc(100% - 50px);
        overflow: hidden auto;
    }

    .model .scroll {
        overflow: hidden auto;
    }

    .model .headerModel {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        padding: 0;
        margin: 0;
        gap: 10px;
    }

    .model .headerModel #title {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
    }

    .model .headerModel #title p {
        font-size: 20px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 100%;
        display: flex;
    }

    .model .headerModel #title p span {
        font-weight: 500;
    }

    .model .headerModel #top-buttons {
        position: inherit;
        height: auto;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .model .headerModel #top-buttons p {
        display: none;
    }

    .model .headerModel #top-buttons button {
        padding: 10px;
        cursor: pointer;
    }

    .model .headerModel #top-buttons .export-buttons {
        display: none;
    }

    .model .headerModel #top-buttons #button-mobile {
        display: flex;
    }

    .model .model-algorithm {
        height: auto;
    }

    .model .model-algorithm .main-algorithm {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 20px;
        align-items: center;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm {
        gap: 10px;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm .name svg {
        height: 20px;
        width: 20px;
    }

    .model .model-algorithm .main-algorithm .left {
        height: auto;
        width: 100%;
    }

    .model .model-algorithm .main-algorithm .left .header-algorithm #algorithm-name,
    .model .model-algorithm .main-algorithm .left .header-algorithm #model-dataset {
        font-size: 14px;
    }

    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-slider p,
    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-checkbox p,
    .model .model-algorithm .main-algorithm .left .model-hyperparameters .model-dropdown p {
        font-size: 14px;
        height: 20px;
    }

    .model .model-algorithm .main-algorithm .model-result-1,
    .model .model-algorithm .main-algorithm .model-result-2 {
        height: 400px;
        width: 100%;
    }

    .model .model-algorithm .main-algorithm .model-info {
        height: auto;
        width: 100%;
    }

    .model .model-algorithm .buttons-bottom-algorithm {
        margin-top: 20px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model .model-algorithm .buttons-bottom-algorithm button,
    .model-algorithm .buttons-bottom-algorithm select {
        padding: 8px 20px;
        border-radius: 8px;
        display: flex;
        font-size: 14px;
        width: 100%;
        justify-content: center;
    }
}