.navModels {
    display: flex;
    align-items: center;
    gap: 1.2em;
}

.modelCards {
    display: flex;
    flex-wrap: wrap;
}

.modelCards button {
    margin-left: 0;
}

@media(max-width:490px) {
    .navModels{
        gap: 0.5em;
        flex-direction: column;
    }
}