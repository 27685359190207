.containerNewKeyApiPopup {
    width: 301px;
    height: 473px;
    text-align: left;
    font-style: normal;
    letter-spacing: 0.2px;
    color: #282828;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    display: flex;
    flex-direction: column;
}

.titlePopup {
    width: 273px;
    height: 34px;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    display: flex;
    align-items: center;
}

.inputs-NewKeyPopup{
    margin-top: 24px;
    width: 301px;
    height: 166px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerNameKey {
    width: inherit;
    height: 87px;
    display: flex;
    flex-direction: column;
}

.containerNameKey input {
    width: inherit;
    height: 31px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    outline: none;
    padding-left: 10px;
}

.containerValidity {
    width: inherit;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerSelect {
    width: inherit;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.containerSelect select {
    height: inherit;
    width: 99px;
    box-sizing: border-box;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    background: transparent;
    cursor: pointer;
    padding-left: 12px;
    color: #282828;
}

.containerSelect p {
    font-size: 11px;
    color: #808080
}

.containerAddressIP {
    margin-top: 25.5px;
    width: inherit;
    height: 189px;
    display: flex;
    flex-direction: column;
}

.containerAccess {
    width: inherit;
    height: 18px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.containerAccess p {
    height: inherit;
}

.containerAddressInput {
    margin-top: 24px;
    width: inherit;
    height: 87px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
}

.containerAddressInput input {
    width: 288px;
    height: 32px;
    outline: none;
    border-radius: 10px;
    padding-left: 10px;
    border: 1px solid #BDBDBD;
}

.containerAddIP {
    width: inherit;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerAddIP #buttonAddApi,
.containerAddIP select {
    height: 30px;
    border-radius: 8px;
}

.containerAddIP #buttonAddApi {
    width: 40%;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.buttonInactive {
    color: #BDBDBD;
}

.buttonActive {
    color: #22577A;
    cursor: pointer;
}

.containerAddIP select {
    width: 50%;
    background-color: transparent;
    padding-left: 10px;
    border: none;
}

.containerButtons {
    margin-top: 24px;
    width: inherit;
    height: 32px;
    padding-left: 103px;
    gap: 30px;
    display: flex;
}

#buttonCancel, 
#buttonCreate {
    border: none;
    font-weight: 500;
    font-size: 14px;
    font-weight: 16px;
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

#buttonCancel {
    width: 122px;
    color: #22577A;
    background-color: transparent;
}

#buttonCreate {
    width: 86px;
    background: #22577A;
    border-radius: 8px;
    color: #FDFDFD;
}

.inputs-NewKeyPopup #error,
.containerAddressIP #error {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: transparent;
    color: red;
    padding-top: 5px;
}