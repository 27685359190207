.profile-edit {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 300px;
    height: 430px;
}

.profile-edit .description {
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    width: 100%;
    margin: 15px 0 15px 0;
}

.profile-edit #profile-form {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 280px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.profile-edit #profile-form .input-title {
    margin-left: 40px;
}

.profile-edit #profile-form .text-input input[type="text"] {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.profile-edit #profile-form .date-input input[type="date"] {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.profile-edit #profile-form .password-input input[type="password"] {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.profile-edit #profile-form .list-input {
    margin-left: 40px;
    margin-right: 52px;
    position: relative;
    margin-bottom: 20px;
}

.profile-edit #profile-form .dropdown-input .default {
    width: 220px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
}

.profile-edit #profile-form .dropdown-input .ui.fluid.search.selection.dropdown {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    display: flex;
    letter-spacing: 0.2px;
    text-align: left;
    align-items: center;
    padding-top: 0.678571em;
    padding-bottom: 0.678571em;
    /* margin-right: 40px; */
}

.profile-edit #profile-form .panel-input .ui.fluid.search.selection.dropdown .divider.default.text {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 15px;
}

.profile-edit #send-button {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
}

.profile-edit #change-picture-button {
    margin-bottom: 15px;
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
}

.profile-edit #change-picture-button:hover {
    cursor: pointer;
}