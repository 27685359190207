.containerInputSlider{
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: flex-start
}

.containerInputSlider .slider {
    width: 72%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7.5px;
}

.containerInputSlider .input {
    width: 15%;
    height: 80%;
    border-radius: 3px;
    border: 1px solid var(--gray-4, #BDBDBD); 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1200px) {

    .containerInputSlider{
        gap: 5px;
    }

    .containerInputSlider .input,
    .containerInputSlider span {
        font-size: 13px;
    }
}

@media (max-width: 1100px) {

    .containerInputSlider .input,
    .containerInputSlider span {
        font-size: 11px;
    }
}

@media (max-width: 1000px) {

    .containerInputSlider{
        gap: 2px;
    }

    .containerInputSlider .input,
    .containerInputSlider span {
        font-size: 9px;
    }
}

@media (max-width: 700px) {

    .containerInputSlider .slider {
        width: 62%;
    }
    
    .containerInputSlider .input {
        width: 25%;
    }

}

@media (max-width: 600px) {

    .containerInputSlider .input,
    .containerInputSlider span {
        font-size: 9.5px;
    }

}

@media (max-width: 500px) {

    .containerInputSlider{
        gap: 12px;
    }

    .containerInputSlider .slider {
        width: 72%;
    }
    
    .containerInputSlider .input {
        width: 15%;
    }

    .containerInputSlider .input,
    .containerInputSlider span {
        font-size: 15px;
    }

}