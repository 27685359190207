.container-language {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    transition: 0.2s;
}

.container-language:hover {
    background-color: #F2F2F2;
}

.container-language div {
    height: 100%;
    max-width: 120px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-language div p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}

.container-language div img {
    height: 24px;
    width: 24px;
}

.select-language {
    display: none;
    position: absolute;
    left: 305px;
    width: 300px;
    flex-direction: column;
    z-index: 1001;
    padding: 5px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat";
    font-size: 14px;
    color: #282828;
    font-style: normal;
    line-height: 13px;
    letter-spacing: 0.2px;


    border-radius: 6px;;
    z-index: 10;
    background-color: #fff;
}

.select-language header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
    max-height: 25px;
    height: auto;
    box-shadow: none !important;
    border: none !important;
}

.select-language header h6 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.select-language header button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.select-language .select-language-content {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
}

.select-language .select-language-content .language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    width: 100%;
    height: 35px;
    cursor: pointer;
    transition: 0.5s;
}

.select-language .select-language-content .language:hover {
    background: #E5E5E5;
}

.select-language .select-language-content .language div {
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-language .select-language-content .language div p {
    padding: 0;
    margin: 0;
}

.select-language .select-language-content .language div img {
    height: 25px;
    width: 25px;
}

@media (max-width: 1200px) {
    .select-language {
        left: 217px;
    }
}

@media (max-width: 940px) {

    .container-language {
        justify-content: center;
        padding: 0;
    }

    .container-language div {
        display: none;
    }
}

@media (max-width: 450px) {
    .select-language {
        left: 55px;
        width: 200px;
    }
}