.tasks-container {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: 0.2s;
}

.tasks-container:hover {
    background-color: #F2F2F2;
}

.tasks {
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #282828;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border: none;
    background-color: transparent;
}

.tasks p {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.table-task {
    margin-top: 0.5em;
    margin-left: 15px;
    text-align: start;
    width: 100%;
}

.table-task th {
    padding-right: 15px;
    text-align: start
}

.no-task {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.no-task th {
    padding-right: 15px;
    text-align: start
}

.tableWrap {
    display: flex;
    height: 200px !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.status-td {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    padding-right: 20px;
}

@media (max-width: 940px) {
    .tasks {
        justify-content: center;
        padding: 0;
    }

    .tasks p {
        display: none;
    }
}