.logout-task {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.logout-task #title {
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    position: absolute;
    left: 20px;
    top: 20px;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 20px;
}

.logout-task #description {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    /* margin-top: 65px; */
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

.logout-task .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}