/* #background-img {
    background: url("../../../images/backgroubd_myprojects_1920.svg");
    background-size: 100%;
    height: 100vh;
    width: 100%;
}

@media (max-width:1154) {
    #background-img {
        background: url("../../../images/backgroubd_myprojects_1376.svg");
        background-size: 100%;
        height: 100%;
        min-height: 770px;
        width: 100%;
    }
} */

.myprojects {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    font-family: Montserrat;
    font-style: normal;
    letter-spacing: 0.2px;
    background: url("../../../images/backgroubd_myprojects_1376.svg");
    background-position: center center;
    background-size: cover;
}

.myprojects .title {
    padding-left: 20px;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    text-align: left;
    padding-bottom: 20px;
}

.myprojects .title p {
    height: 100%;
    width: 60%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.myprojects .header {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.myprojects .section-title {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    gap: 20px;
}

.myprojects .section-title div {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.myprojects .section-title .select-all {
    margin-right: 5rem;
    gap: 1.5rem;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 40px;
    height: 80%;
    padding: 0;
}

.myprojects .section-title .select-all .trash-icon {
    cursor: pointer;
}

.myprojects .section-title div p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
}

.myprojects .section-title div button {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
}

.myprojects .conteudo {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 10px;
    flex-wrap: wrap;
    max-height: 80vh;
    overflow: hidden auto;
    width: auto;
}

.noProjects {

    font-family: Montserrat, sans-serif;
    font-size: 24px;
    color: lightgrey;
    padding-left: 0.6rem;
    margin-top: 1rem;

}

@media (max-width: 450px) {

    .myprojects {
        padding: 10px 0px;
    }

    .myprojects .title,
    .myprojects .header,
    .myprojects .section-title {
        padding-left: 10px;
    }

    .myprojects .section-title .select-all {
        margin-right: 2rem;
    }
}