.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.popup-inner {
    display: flex;
    position: relative;
    padding: 20px;
    width: auto;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.popup-inner .close-btn {
    position: absolute;
    top: 7px;
    right: 5px;
    cursor: pointer;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    z-index: 10;
}

.tasks-pop {
    align-items: baseline;
    gap: 200px;
}