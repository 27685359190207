.containerAvailableDataset{
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.containerAvailableDataset .containerHeader {
    width: 100%;
    height: 10%;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerAvailableDataset .containerHeader  .containerSelectedAll {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.containerAvailableDataset .containerHeader .containerSelectedAll div {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2.5px solid #c9c7c7;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;
}

.containerAvailableDataset .containerHeader .containerSelectedAll p {
    font-size: 1em;
    font-weight: 400;
}

.containerAvailableDataset .containerHeader .containerSearch {
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerAvailableDataset .containerHeader .containerSearch .containerInput{
    display: flex;
    align-items: center;
    height: 80%;
    width: 90%;
    justify-content: space-between;
    border: 1px solid #BDBDBD;
    border-radius: 13px;
    padding: 0 10px;
}

.containerAvailableDataset .containerHeader .containerSearch .containerInput input {
    height: 100%;
    width: 90%;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 0.9em;
}

.containerAvailableDataset .containerContent {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0px;
    gap: 10px;
    overflow: hidden auto;
}

.containerAvailableDataset .containerContent .containerCard {
    position: relative;
    padding: 1px;
    border-radius: 8px;
}

.containerAvailableDataset .containerContent .containerCard .containerBox {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 20px;
    height: 20px;
    border: 2.5px solid #c9c7c7;
    z-index: 1;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerAvailableDataset .containerContent .selected {
    background: #dfdddd;
}

@media (max-width: 600px){
    .containerAvailableDataset {
        font-size: 13px;
    }

    .containerAvailableDataset .containerHeader {
        padding: 0 10px;
    }

    .containerAvailableDataset .containerHeader  .containerSelectedAll {
        gap: 5px;
        font-size: 0.85em;
    }

    .containerAvailableDataset .containerHeader .containerSearch {
        width: 55%;
        font-size: 0.8em;
    }
}