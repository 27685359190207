.container-fpgrowth-modelresult {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: relative;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 0.2px;
}

.container-fpgrowth-modelresult .fpgrowth-result {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external {
    width: 97.28%;
    height: 70px;
    background-color: #F2F2F2;
    display: flex;
    margin: 1.15% auto 0px auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal {
    height: 53.85%;
    width: 97.2%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    width: 64%;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input input {
    height: 100%;
    width: 95%;
    border: none;
    background-color: transparent;
    outline: none;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons {
    width: 21.33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button {
    padding: 0;
    margin: 0;
    width: 50%;
    height: 100%;
    border: 1px solid #79747E;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button p {
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button:hover{
    background: rgba(56, 163, 165, 0.20);
}

.container-fpgrowth-modelresult table {
    display: flex;
    flex-direction:  column;
    padding: 0;
    margin: 0;
    width: 100%;
    padding: 12px;
    width: 97.28%;
    height: 74.33%;
    margin: 4.31% auto 0px auto;
    overflow: auto;
}

.container-fpgrowth-modelresult table tr {
    height: 39px;
    width: 100%;
}

.container-fpgrowth-modelresult table thead,
.container-fpgrowth-modelresult table tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container-fpgrowth-modelresult table thead {
    border-bottom: 1px solid #BFBFBF;
    font-weight: 600;
    line-height: 15px;
}

.container-fpgrowth-modelresult table tbody {
    width: 100%;
    height: 95.35%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}


.container-fpgrowth-modelresult table tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.15%;
}

.container-fpgrowth-modelresult table thead tr th {
    font-weight: 600;
    width: 14.28%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-fpgrowth-modelresult table tbody tr th {
    width: 14.28%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.container-fpgrowth-modelresult table tr th p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    line-height: 15px;
    letter-spacing: 0.2px; 
}

@media (max-width: 1000px) {

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input svg {
        padding: 0px;
        margin: 0;
        height: 20px;
        width: 20px;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input input {
        font-size: 14px;
        padding-left: 5px;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons {
        width: 35%;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button p {
        font-size: 14px;
        font-weight: 600;
    }
}

@media (max-width: 800px) {
    .container-fpgrowth-modelresult .container-fpgrowth-box-external {
        height: 45px;
        margin: 5px auto 0px auto;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal {
        height: 70%;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input input,
    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button p  {
        font-size: 13px;
    }

    
    .container-fpgrowth-modelresult table tr th p {
        font-size: 10px;
    }

}

@media (max-width: 500px) {
    .container-fpgrowth-modelresult .container-fpgrowth-box-external {
        height: 40px;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal {
        height: 80%;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input {
        width: 59%;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-input input,
    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons button p {
        font-size: 11.5px;
    }

    .container-fpgrowth-modelresult .container-fpgrowth-box-external .container-fpgrowth-box-internal .container-fpgrowth-buttons {
        width: 40%;
    }

    .container-fpgrowth-modelresult table thead,
    .container-fpgrowth-modelresult table tbody {
        width: 500px;   
    }

    .container-fpgrowth-modelresult table tr th,
    .container-fpgrowth-modelresult table tr th {
        width: 20%;
    }

    .container-fpgrowth-modelresult table tr th p {
        font-size: 10px;
    }
}