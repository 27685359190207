.password-reset-popup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    height: 100vh;
    max-height: 150px;
    margin: 1rem auto;
}

.password-reset-popup #password-input {
    width: 80%;
}

.response-reset-password {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: black;
    max-width: 300px;
    min-width: 300px;
    gap: 1rem;
}