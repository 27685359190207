.about{
    display: flex;
    flex-direction: column;
    width: 58%;
    background-color: transparent;
    font-size: 10px;
    margin: 100px auto 0px auto;
}

.about .title h1{
    font-style: normal;
    font-weight: 700;
    font-size: 5em;
    line-height: 48px;
    color: #282828;
    text-align: left;
    margin-bottom: 50px;
}

.about .description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    text-align: left;
    font-style: normal;
    font-weight: 50;
    font-size: 1.6em;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #282828;
    text-align: left;
}

.about .description div{
    margin-bottom: 30px;
}

.about .image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about .image img {
    width: 100%;
}

@media (max-width: 1200px){

    .about{
        font-size: 11px;
        width: 65%;
    }

}

@media (max-width: 760px) {

    .about{
        font-size: 12px;
        width: 80%;
    }

    .about .title h1 {
        font-size: 4em;
    }
}