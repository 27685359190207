#rename-model-popup {
    width: 260px;
    height: auto;
    display: flex;
    flex-direction: column;
}

#rename-model-popup #title-msg {
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    margin-bottom: 20px;
    width: 100%;
}

#rename-model-popup #description {
    font-family: Montserrat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: black;
    background-color: transparent;
    border: 0px solid transparent;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
    border-radius: 5px;
}

#rename-model-popup #buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.rename-input {
    font-size: 14px;
    width: 225px;
}