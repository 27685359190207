.user-profile-popup {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    left: 300px;
    border-radius: 6px;
    z-index: 10;
    width: 240px;
    height: 344px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat', sans-serif;
    margin-left: 5px;
}

.user-profile-popup .user-profile-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 182px;
    width: 100%;
    padding: 18px 18px 0px 18px;
    border-bottom: 1px solid #e5e5e5;
}

.user-profile-popup .picture-container {
    width: 100%;
    height: 78px;
}

.user-profile-popup #user-profile-picture {
    height: 78px;
    width: 78px;
    overflow: hidden;
    object-fit: scale-down;
    border-radius: 50%;
}

.user-profile-popup .user-profile-name {
    display: block;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 34px;
}


.user-profile-popup .user-profile-email {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 34px;
}

.user-profile-popup .user-profile-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.user-profile-popup .user-profile-buttons .user-profile-button {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 18px;
    line-height: 18px;
    border-bottom: 1px solid #e5e5e5;
}

.user-profile-popup .user-profile-buttons .user-profile-button .button-cursor {
    cursor: pointer;
    border: none;
    background: #ffffff;
}

.user-profile-popup .user-profile-buttons .user-profile-button .button-cursor:hover {
    cursor: pointer;
}

.user-profile-popup .user-profile-buttons .user-profile-button #feedback-button {
    color: #0046F5
}

@media (max-width: 1400px) {
    .user-profile-popup {
        left: 212px;
    }
}

@media (max-width: 940px) {
    .user-profile-popup {
        left: 50px;
    }
}