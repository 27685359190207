.mode-tabs {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;
}

.mode-tabs button {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.3rem;
    background-color: #E5E5E5;
    border-style: none;
}

.mode-tabs button.visualize {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.mode-tabs button.create {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.mode-tabs button:hover {
    background-color: #dbc9c9;
}

.mode-tabs button.activated {
    background-color: #22577A;
    color: #FDFDFD;
    /* Cor de fundo vermelha quando ativado */
    /* Adicione outros estilos conforme necessário */
}

@media (max-width: 1200px) {
    .mode-tabs button {
        font-size: 11px;
    }
}

@media (max-width: 1000px) {
    .mode-tabs button {
        font-size: 10px;
    }
}

@media (max-width: 750px) {
    .mode-tabs button {
        font-size: 9px;
    }
}

@media (max-width: 600px) {
    .mode-tabs button {
        font-size: 7px;
    }
}

@media (max-width: 500px) {
    .mode-tabs button {
        font-size: 12px;
    }

    .mode-tabs button {
        padding: 0.4rem;
    }
}